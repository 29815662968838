import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import FormLayout, { FormLayoutElement } from '../../elements/FormLayout';
import InputField, { InputFieldNumber } from '../../elements/InputField';
import './TestPhases.scss';
import Dialog from '../../components/Dialog';
import Table from '../components/Table';
import TableHeader from '../elements/TableHeader';
import TableRow from '../elements/TableRow';
import { Navigate, Route, Routes } from 'react-router-dom';
import AdminRadioButton from '../../elements/RadioButton';
import SearchField from '../../elements/SearchField';
import Pagination from '../components/Pagination';
import {
  ACTIVE_STATE,
  FrameProfileRoof,
  SashProfileRoof,
  TestPhaseAerodynamicCaseRoofWithOptionalId,
  TestPhaseApplicationRoofWithOptionalId,
  TestPhaseRoof,
  TestPhaseSeriesRoofWithOptionalId,
  TestPhaseSystemRoofWithOptionalId,
  TestPhaseWinddeflectorConfiguration,
} from '../../redux/admin/adminRoofReducer';
import { useDispatch, useSelector } from 'react-redux';
import { AdminState, AdminThunkDispatch } from '../../redux/admin/adminStore';
import {
  ActiveFurtherInformationUiSection,
  AERODYNAMIC_CASE_DESCRIPTIONS,
  Funktionssicherheit,
  POSSIBLE_TEST_PHASE_APPLICATIONS_OPTIONS_ROOF,
  UiConstants,
  UmgebungsTemp,
  Waermebestaendigkeit,
} from '../constants';
import {
  changeTestPhaseRoof,
  copyTestPhaseRoof,
  createTestPhaseRoof,
  selectEditableTestPhaseRoof,
} from '../../redux/admin/adminRoofActions';
import {
  addTestPhaseAerodynamicCaseToEditedApplication,
  addTestPhaseApplicationToEditedSeriesOpeningDirectionRoof,
  addTestPhaseSeriesToEditableTestPhaseSystemRoof,
  addTestPhaseSystemToEditableTestPhaseRoof,
  deselectedAerodynamicCases,
  initializeNewEditableTestPhaseRoof,
  removeTestPhaseAerodynamicCaseToEditedApplication,
  removeTestPhaseApplicationFromEditedSeriesRoof,
  removeTestPhaseSeriesFromEditableTestPhaseSystemRoof,
  removeTestPhaseSystemFromEditableTestPhaseRoof,
  updateEditedTestPhaseAerodynamicCaseRoof,
  updateEditedTestPhaseApplicationRoof,
  updateEditedTestPhaseRoof,
  updateEditedTestPhaseSeriesRoof,
  updateEditedTestPhaseSystemRoof,
} from '../../redux/admin/editedTestPhaseActionsRoof';
import TestPhaseSelectField from '../components/TestPhaseSelectField';
import {
  useEditedTestApplicationRoof,
  useEditedTestPhaseRoof,
  useEditedTestPhaseSeries,
  useEditedTestPhaseSeriesRoof,
  useEditedTestPhaseSystemRoof,
} from '../../hooks/selectorHooks';
import Button, { ButtonType } from '../../elements/Button';
import WindOrSnowLoadsDialog from './WindOrSnowLoadsDialog';
import { AssignSelectGroup } from '../components/AssignSelectGroup';
import {
  ApplicationOverviewsDialog,
  getTestPhaseFunctions,
} from './FacadeTestPhases';
import { CVKurve, Series, System } from '../../redux/admin/adminFacadeReducer';
import {
  MenuPlacement,
  SelectField,
  selectFieldValue,
  SelectOption,
} from '../../elements/SelectField';
import {
  updateEditedTestPhaseApplication,
  updateEditedTestPhaseApplicationOverviews,
  updateEditedTestPhaseLoads,
} from '../../redux/admin/editedTestPhaseActions';
import Headline, { HeadlineSize } from '../../elements/Headline';
import CopySeriesDialogRoof from './CopySeriesDialogRoof';
import { AnyAction } from 'redux';
import AdminNavLink from '../common/AdminNavLink';
import { useAdminSearch, useNumberState } from '../hooks';
import FieldLabel from '../../elements/FieldLabel';
import { Hint } from '../elements/Hint';
import FormLayoutSubgroupTitle from '../../elements/FormLayoutSubgroupTitle';
import { Switch } from '../../elements/Switch';

const SERIES_MATERIAL_OPTIONS: Record<string, string> = {
  alu: 'Alu',
  holz: 'Holz',
  glas: 'Glas',
  stahl: 'Stahl',
};

const APPLICATION_OPTIONS: Record<string, string> = {
  einzelklappe: 'Einzelklappe Kipp',
  einzelklappeklapp: 'Einzelklappe Klapp',
  doubleFlapPentRoofBottomTopHung: 'Doppelklappe Kipp-Klapp Pultdach',
  doubleFlapSaddleRoofBottomHungBottomHung: 'Doppelklappe Kipp-Kipp Satteldach',
  doppelklappetonnendach: 'Doppelklappe Tonnendach',
  kipp: 'Kipp',
  klapp: 'Klapp',
  drehL: 'Dreh',
  doubleFlapSideHungSideHungPentRoof: 'Doppelklappe Dreh-Dreh Pultdach',
  doubleFlapSideHungSideHungSaddleRoof: 'Doppelklappe Dreh-Dreh Satteldach',
};

function TestPhasesDataDialogTabNav(): ReactElement {
  return (
    <div className="admin__tab-nav test-phases__tab-nav">
      <AdminNavLink to="/admin/roof/test-phases/basic">
        <Routes>
          <Route
            path="/"
            element={<Navigate replace to="/admin/roof/test-phases/basic" />}
          />
        </Routes>
        Allgemein
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/test-phases/further-information">
        Weitere Angaben
      </AdminNavLink>
      <AdminNavLink to="/admin/roof/test-phases/general-dependencies">
        Generelle Abhängigkeiten
      </AdminNavLink>
    </div>
  );
}

export const [applicationAlreadyIncludedInOpeningType, deselectedApplications] =
  getTestPhaseFunctions(
    (application: TestPhaseApplicationRoofWithOptionalId) =>
      application.application,
    POSSIBLE_TEST_PHASE_APPLICATIONS_OPTIONS_ROOF,
  );

const TestPhasesDataDialogGeneralDependencies: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedTestPhase = useEditedTestPhaseRoof();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <div className="test-phases__data-dialog">
      <FormLayout>
        <FormLayoutElement rowStart={1} columnStart={1} columnWidth={3}>
          <FormLayout>
            <FormLayoutElement rowStart={1} columnStart={1} columnWidth={6}>
              <Headline size={HeadlineSize.LG} normalHeight={true}>
                Einzelklappe
              </Headline>
              <Headline size={HeadlineSize.MD} normalHeight={true}>
                (Nur Kipp) Bei einer Einbauneigung (°) im Bereich von:
              </Headline>
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={1} columnWidth={1}>
              <InputFieldNumber
                onChange={n =>
                  dispatch(
                    updateEditedTestPhaseRoof({
                      ...editedTestPhase,
                      minEinbauneigungBereich3EK: n,
                    }),
                  )
                }
                label="min."
                placeholder="z.B. 42"
                value={editedTestPhase.minEinbauneigungBereich3EK}
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={2} columnWidth={1}>
              <InputFieldNumber
                onChange={n =>
                  dispatch(
                    updateEditedTestPhaseRoof({
                      ...editedTestPhase,
                      maxEinbauneigungBereich3EK: n,
                    }),
                  )
                }
                label="max."
                placeholder="z.B. 42"
                value={editedTestPhase.maxEinbauneigungBereich3EK}
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={3} columnStart={1} columnWidth={6}>
              <Headline size={HeadlineSize.MD} normalHeight={true}>
                und einer Flügelhöhe (H FR)(mm) von:
              </Headline>
            </FormLayoutElement>
            <FormLayoutElement rowStart={4} columnStart={1} columnWidth={1}>
              <InputFieldNumber
                onChange={n =>
                  dispatch(
                    updateEditedTestPhaseRoof({
                      ...editedTestPhase,
                      fluegelhoeheKleinerGleichBereich3EK: n,
                    }),
                  )
                }
                label="<="
                placeholder="z.B. 42"
                value={editedTestPhase.fluegelhoeheKleinerGleichBereich3EK}
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={5} columnStart={1} columnWidth={6}>
              beschränkt sich der Öffnungswinkel (°) auf
            </FormLayoutElement>
            <FormLayoutElement rowStart={6} columnStart={1} columnWidth={1}>
              <InputFieldNumber
                onChange={n =>
                  dispatch(
                    updateEditedTestPhaseRoof({
                      ...editedTestPhase,
                      maxOeffnungswinkel1Bereich3EK: n,
                    }),
                  )
                }
                label="max."
                placeholder="z.B. 42"
                value={editedTestPhase.maxOeffnungswinkel1Bereich3EK}
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={7} columnStart={1} columnWidth={3}>
              und
            </FormLayoutElement>
            <FormLayoutElement rowStart={8} columnStart={1} columnWidth={1}>
              <InputFieldNumber
                onChange={n =>
                  dispatch(
                    updateEditedTestPhaseRoof({
                      ...editedTestPhase,
                      fluegelhoeheGroesserBereich3EK: n,
                    }),
                  )
                }
                label=">"
                placeholder="z.B. 42"
                value={editedTestPhase.fluegelhoeheGroesserBereich3EK}
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={9} columnStart={1} columnWidth={6}>
              beschränkt sich der Öffnungswinkel (°) auf
            </FormLayoutElement>
            <FormLayoutElement rowStart={10} columnStart={1} columnWidth={1}>
              <InputFieldNumber
                onChange={n =>
                  dispatch(
                    updateEditedTestPhaseRoof({
                      ...editedTestPhase,
                      maxOeffnungswinkel2Bereich3EK: n,
                    }),
                  )
                }
                label="max."
                placeholder="z.B. 42"
                value={editedTestPhase.maxOeffnungswinkel2Bereich3EK}
              />
            </FormLayoutElement>
          </FormLayout>
        </FormLayoutElement>

        <FormLayoutElement
          rowStart={1}
          columnStart={4}
          columnWidth={3}
          rowHeight={3}
        >
          <FormLayout>
            <FormLayoutElement rowStart={1} columnStart={1} columnWidth={6}>
              <Headline size={HeadlineSize.LG} normalHeight={true}>
                Doppelklappe Kipp-Klapp Pultdach
              </Headline>
            </FormLayoutElement>
            <FormLayoutElement rowStart={2} columnStart={1} columnWidth={6}>
              <Headline size={HeadlineSize.MD} normalHeight={true}>
                Bei einer Einbauneigung (°) im Bereich von:
              </Headline>
            </FormLayoutElement>
            <FormLayoutElement rowStart={3} columnStart={1} columnWidth={1}>
              <InputFieldNumber
                onChange={n =>
                  dispatch(
                    updateEditedTestPhaseRoof({
                      ...editedTestPhase,
                      minEinbauneigungBereich1DK: n,
                    }),
                  )
                }
                label="min."
                placeholder="z.B. 42"
                value={editedTestPhase.minEinbauneigungBereich1DK}
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={3} columnStart={2} columnWidth={1}>
              <InputFieldNumber
                onChange={n =>
                  dispatch(
                    updateEditedTestPhaseRoof({
                      ...editedTestPhase,
                      maxEinbauneigungBereich1DK: n,
                    }),
                  )
                }
                label="max."
                placeholder="z.B. 42"
                value={editedTestPhase.maxEinbauneigungBereich1DK}
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={4} columnStart={1} columnWidth={6}>
              <Headline size={HeadlineSize.MD} normalHeight={true}>
                soll die Flügelbreite/Flügelhöhe (B FR/H FRZK)
              </Headline>
            </FormLayoutElement>
            <FormLayoutElement rowStart={5} columnStart={1} columnWidth={1}>
              <InputFieldNumber
                onChange={n =>
                  dispatch(
                    updateEditedTestPhaseRoof({
                      ...editedTestPhase,
                      verhaeltnisFluegelbreiteFluegelhoeheBereich1DK: n,
                    }),
                  )
                }
                label=">="
                placeholder="z.B. 42"
                value={
                  editedTestPhase.verhaeltnisFluegelbreiteFluegelhoeheBereich1DK
                }
              />
            </FormLayoutElement>
            <FormLayoutElement rowStart={6} columnStart={1} columnWidth={6}>
              <Headline size={HeadlineSize.MD} normalHeight={true}>
                sein
              </Headline>
            </FormLayoutElement>
          </FormLayout>
        </FormLayoutElement>
      </FormLayout>
    </div>
  );
};

const TestPhasesDataDialogFurtherInformation: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedTestPhase = useEditedTestPhaseRoof();
  const editedTestPhaseSystem = useEditedTestPhaseSystemRoof();
  const editedTestPhaseSeries = useEditedTestPhaseSeriesRoof();
  const editedTestPhaseApplication = useEditedTestApplicationRoof();

  const allSystems = useSelector<AdminState, System[]>(
    state => state.adminFacade.systems,
  );

  const allSeriesForSystem = useSelector<AdminState, Series[]>(
    state =>
      state.adminRoof.series.filter(
        series => series?.system.id === editedTestPhaseSystem?.system.id,
      ) || [],
  );

  const [activeSection, setActiveSection] =
    useState<ActiveFurtherInformationUiSection>(
      ActiveFurtherInformationUiSection.SYSTEM,
    );

  const [selectSystemKey, setSelectSystemKey] = useState<string>('');
  const [selectSeriesKey, setSelectSeriesKey] = useState<string>('');
  const [selectApplicationKey, setSelectApplicationKey] = useState<string>('');
  const [selectAerodynamicCaseKey, setSelectAerodynamicCaseKey] =
    useState<string>('');
  const [copySeriesDialogIsShown, setCopySeriesDialogIsShown] =
    useState<boolean>(false);

  useEffect(() => {
    if (activeSection !== ActiveFurtherInformationUiSection.SYSTEM) {
      setSelectSystemKey(Date.now().toString());
    }
    if (activeSection !== ActiveFurtherInformationUiSection.SERIES) {
      setSelectSeriesKey(Date.now().toString());
    }
    if (activeSection !== ActiveFurtherInformationUiSection.APPLICATION) {
      setSelectApplicationKey(Date.now().toString());
    }
    if (activeSection !== ActiveFurtherInformationUiSection.AERODYNAMIC_CASE) {
      setSelectAerodynamicCaseKey(Date.now().toString());
    }
    if (activeSection === ActiveFurtherInformationUiSection.SYSTEM) {
      dispatch(updateEditedTestPhaseSeriesRoof(undefined));
      dispatch(updateEditedTestPhaseApplicationRoof(undefined));
      dispatch(updateEditedTestPhaseAerodynamicCaseRoof(undefined));
    }
    if (activeSection === ActiveFurtherInformationUiSection.SERIES) {
      dispatch(updateEditedTestPhaseApplicationRoof(undefined));
      dispatch(updateEditedTestPhaseAerodynamicCaseRoof(undefined));
    }
    if (activeSection === ActiveFurtherInformationUiSection.APPLICATION) {
      dispatch(updateEditedTestPhaseAerodynamicCaseRoof(undefined));
    }
  }, [activeSection]);

  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <div className="test-phases__data-dialog roof-test-phases__data-dialog">
      <FormLayout additionalClass="test-phases__data-dialog-layout-further-information">
        <div className="test-phases__edit-phases-select-row test-phases__edit-phases-select-row-roof">
          <TestPhaseSelectField
            componentClassIdentifier="system"
            selectKey={selectSystemKey}
            isActive={
              activeSection === ActiveFurtherInformationUiSection.SYSTEM
            }
            setActiveSection={setActiveSection}
            label="System"
            dialogHeadings={{
              dialog: 'Systeme zuordenen',
              leftTable: 'alle Systeme',
              rightTable: 'zugeordnete Systeme',
            }}
            onFocus={() =>
              setActiveSection(ActiveFurtherInformationUiSection.SYSTEM)
            }
            displayNameExtractor={s => s.name}
            saveAction={(selected: System[], deselected: System[]) => {
              selected.forEach(selectedSystem =>
                dispatch(
                  addTestPhaseSystemToEditableTestPhaseRoof(selectedSystem),
                ),
              );
              deselected.forEach(deselectedSystem =>
                dispatch(
                  removeTestPhaseSystemFromEditableTestPhaseRoof(
                    deselectedSystem,
                  ),
                ),
              );
            }}
            elementSelected={(element: System) => {
              dispatch(
                updateEditedTestPhaseSystemRoof(
                  editedTestPhase.testPhaseSystems.find(
                    testPhaseSystem => testPhaseSystem.system.id === element.id,
                  )!,
                ),
              );
            }}
            deselectedElements={allSystems.filter(
              system =>
                !editedTestPhase.testPhaseSystems
                  .map(testPhaseSystem => testPhaseSystem.system.id)
                  .includes(system.id),
            )}
            selectedElements={editedTestPhase.testPhaseSystems.map(
              testPhaseSystem => testPhaseSystem.system,
            )}
            highlightSelectedElement={true}
          />

          <TestPhaseSelectField
            componentClassIdentifier="serie"
            selectKey={selectSeriesKey}
            isActive={
              activeSection === ActiveFurtherInformationUiSection.SERIES
            }
            setActiveSection={setActiveSection}
            label="Serie"
            dialogHeadings={{
              dialog: 'Serie zuordnen',
              leftTable: 'verfügbare Serien',
              rightTable: 'zugeordnete Serien',
            }}
            withCopyFunction={true}
            copyButtonPressed={() => setCopySeriesDialogIsShown(true)}
            onFocus={() =>
              setActiveSection(ActiveFurtherInformationUiSection.SERIES)
            }
            deselectedElements={allSeriesForSystem.filter(
              series =>
                !editedTestPhaseSystem?.testPhaseSeries
                  .map(testPhaseSeries => testPhaseSeries.series.id)
                  .includes(series.id),
            )}
            selectedElements={
              editedTestPhaseSystem?.testPhaseSeries?.map(
                testPhaseSeries => testPhaseSeries.series,
              ) || []
            }
            saveAction={(selected: Series[], deselected: Series[]) => {
              selected.forEach(selectedSeries =>
                dispatch(
                  addTestPhaseSeriesToEditableTestPhaseSystemRoof(
                    selectedSeries,
                  ),
                ),
              );
              deselected.forEach(deselectedSeries =>
                dispatch(
                  removeTestPhaseSeriesFromEditableTestPhaseSystemRoof(
                    deselectedSeries,
                  ),
                ),
              );
            }}
            displayNameExtractor={series => series.name}
            elementSelected={(element: Series) => {
              dispatch(
                updateEditedTestPhaseSeriesRoof(
                  editedTestPhaseSystem.testPhaseSeries.find(
                    testPhaseSeries => testPhaseSeries.series.id === element.id,
                  )!,
                ),
              );
            }}
            highlightSelectedElement={
              activeSection !== ActiveFurtherInformationUiSection.SYSTEM
            }
          />
          <CopySeriesDialogRoof
            shown={copySeriesDialogIsShown}
            setShown={setCopySeriesDialogIsShown}
          />
          <TestPhaseSelectField
            componentClassIdentifier="application"
            selectKey={selectApplicationKey}
            isActive={
              activeSection === ActiveFurtherInformationUiSection.APPLICATION
            }
            setActiveSection={setActiveSection}
            label="Anwendung"
            dialogHeadings={{
              dialog: 'Anwendung zuordenen',
              leftTable: 'verfügbare Anwendungen',
              rightTable: 'zugeordnete Anwendungen',
            }}
            withCopyFunction={false}
            onFocus={() =>
              setActiveSection(ActiveFurtherInformationUiSection.APPLICATION)
            }
            deselectedElements={deselectedApplications(
              editedTestPhaseSeries?.anwendungen,
            )}
            selectedElements={editedTestPhaseSeries?.anwendungen || []}
            saveAction={(
              selected: TestPhaseApplicationRoofWithOptionalId[],
              deselected: TestPhaseApplicationRoofWithOptionalId[],
            ) => {
              selected.forEach(selectedApplication =>
                dispatch(
                  addTestPhaseApplicationToEditedSeriesOpeningDirectionRoof(
                    selectedApplication,
                  ),
                ),
              );
              deselected.forEach(deselectedApplication =>
                dispatch(
                  removeTestPhaseApplicationFromEditedSeriesRoof(
                    deselectedApplication,
                  ),
                ),
              );
            }}
            displayNameExtractor={application =>
              APPLICATION_OPTIONS[application.application]
            }
            elementSelected={(
              element: TestPhaseApplicationRoofWithOptionalId,
            ) =>
              dispatch(
                updateEditedTestPhaseApplicationRoof(
                  editedTestPhaseSeries.anwendungen.find(
                    application =>
                      application.application === element.application,
                  )!,
                ),
              )
            }
            highlightSelectedElement={
              activeSection !== ActiveFurtherInformationUiSection.SERIES
            }
          />
          <TestPhaseSelectField
            componentClassIdentifier="aerodynamicCase"
            selectKey={selectAerodynamicCaseKey}
            isActive={
              activeSection ===
              ActiveFurtherInformationUiSection.AERODYNAMIC_CASE
            }
            setActiveSection={setActiveSection}
            label="Aerodynamik Fälle"
            dialogHeadings={{
              dialog: 'Aerodynamik Fall zuordnen',
              leftTable: 'verfügbare Fälle',
              rightTable: 'zugeordnete Fälle',
            }}
            withCopyFunction={false}
            onFocus={() =>
              setActiveSection(
                ActiveFurtherInformationUiSection.AERODYNAMIC_CASE,
              )
            }
            deselectedElements={deselectedAerodynamicCases(
              editedTestPhaseApplication?.testPhaseAerodynamicCaseRoofs,
            )}
            selectedElements={
              editedTestPhaseApplication?.testPhaseAerodynamicCaseRoofs || []
            }
            saveAction={(
              selected: TestPhaseAerodynamicCaseRoofWithOptionalId[],
              deselected: TestPhaseAerodynamicCaseRoofWithOptionalId[],
            ) => {
              selected.forEach(selectedAerodynamicCase =>
                dispatch(
                  addTestPhaseAerodynamicCaseToEditedApplication(
                    selectedAerodynamicCase,
                  ),
                ),
              );
              deselected.forEach(deselectedAerodynamicCase =>
                dispatch(
                  removeTestPhaseAerodynamicCaseToEditedApplication(
                    deselectedAerodynamicCase,
                  ),
                ),
              );
            }}
            displayNameExtractor={aerodynamicCase =>
              AERODYNAMIC_CASE_DESCRIPTIONS[aerodynamicCase.aerodynamicCase]
            }
            elementSelected={(
              element: TestPhaseAerodynamicCaseRoofWithOptionalId,
            ) => {
              dispatch(
                updateEditedTestPhaseAerodynamicCaseRoof(
                  editedTestPhaseApplication.testPhaseAerodynamicCaseRoofs.find(
                    aerodynamicCase =>
                      aerodynamicCase.aerodynamicCase ===
                      element.aerodynamicCase,
                  )!,
                ),
              );
            }}
            highlightSelectedElement={
              activeSection !== ActiveFurtherInformationUiSection.APPLICATION
            }
          />
        </div>
        <EditTestPhasesRow
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
      </FormLayout>
    </div>
  );
};

const TestPhasesDataDialogBasic: FC<React.PropsWithChildren<unknown>> = () => {
  const editedTestPhase = useEditedTestPhaseRoof();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  return (
    <FormLayout additionalClass="test-phases__data-dialog-layout-basic">
      <FormLayoutElement rowStart={1} columnStart={1} columnWidth={2}>
        <InputField
          label="Name"
          placeholder="Testphase 0815"
          value={editedTestPhase.name}
          additionalClass="test-phases__name"
          onChange={name =>
            dispatch(
              updateEditedTestPhaseRoof({ ...editedTestPhase, name: name }),
            )
          }
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={2} columnStart={1} columnWidth={6}>
        <AdminRadioButton
          checked={editedTestPhase.active === ACTIVE_STATE.INACTIVE}
          name="Deaktiviert"
          onChange={() =>
            dispatch(
              updateEditedTestPhaseRoof({
                ...editedTestPhase,
                active: ACTIVE_STATE.INACTIVE,
              }),
            )
          }
        />
        <AdminRadioButton
          checked={editedTestPhase.active === ACTIVE_STATE.PREVIEW}
          name="Vorschau"
          onChange={() =>
            dispatch(
              updateEditedTestPhaseRoof({
                ...editedTestPhase,
                active: ACTIVE_STATE.PREVIEW,
              }),
            )
          }
        />
        <AdminRadioButton
          checked={editedTestPhase.active === ACTIVE_STATE.ACTIVE}
          name="Aktiv"
          onChange={() =>
            dispatch(
              updateEditedTestPhaseRoof({
                ...editedTestPhase,
                active: ACTIVE_STATE.ACTIVE,
              }),
            )
          }
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={3} columnStart={1} columnWidth={6}>
        <Headline
          additionalClass="headline--normal-height"
          size={HeadlineSize.MD}
        >
          Windleitwände
        </Headline>
      </FormLayoutElement>
      <FormLayoutElement rowStart={4} columnStart={2} columnWidth={1}>
        <InputFieldNumber
          onChange={n =>
            dispatch(
              updateEditedTestPhaseRoof({
                ...editedTestPhase,
                maxWLWHoeheAlu: n,
              }),
            )
          }
          label="maximale Höhe (mm)"
          placeholder="z.B. 42"
          value={editedTestPhase.maxWLWHoeheAlu}
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={5} columnStart={2} columnWidth={1}>
        <InputFieldNumber
          onChange={n =>
            dispatch(
              updateEditedTestPhaseRoof({
                ...editedTestPhase,
                maxWLWHoeheGlas: n,
              }),
            )
          }
          label="maximale Höhe (mm)"
          placeholder="z.B. 42"
          value={editedTestPhase.maxWLWHoeheGlas}
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={6} columnStart={1} columnWidth={2}>
        <InputFieldNumber
          onChange={n =>
            dispatch(
              updateEditedTestPhaseRoof({
                ...editedTestPhase,
                minAbstandDrehband: n,
              }),
            )
          }
          label="min. Abstand vom Drehband bei seitl. Montage (%) bei Kipp- oder Klappanwendung"
          placeholder="z.B. 42"
          value={editedTestPhase.minAbstandDrehband}
        />
      </FormLayoutElement>
    </FormLayout>
  );
};

const TestPhasesDataDialogFurtherInformationSystems: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedTestPhaseSystem = useSelector<
    AdminState,
    TestPhaseSystemRoofWithOptionalId
  >(state => state.adminRoof.editedTestPhaseSystem!);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();

  if (!editedTestPhaseSystem) {
    return null;
  }

  function setKlassFunk(value: Funktionssicherheit): void {
    dispatch(
      updateEditedTestPhaseSystemRoof(editedTestPhaseSystem, {
        klassFunk: value,
      }),
    );
  }

  function setTemperature(value: UmgebungsTemp): void {
    dispatch(
      updateEditedTestPhaseSystemRoof(editedTestPhaseSystem, {
        umgebungstemp: value,
      }),
    );
  }

  function setHeat(value: Waermebestaendigkeit): void {
    dispatch(
      updateEditedTestPhaseSystemRoof(editedTestPhaseSystem, {
        klassWaermebest: value,
      }),
    );
  }

  function setMaxWindLoad(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSystemRoof(editedTestPhaseSystem, {
        maxWindLoad: value,
      }),
    );
  }

  function setMaxSnowLoad(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSystemRoof(editedTestPhaseSystem, {
        maxSnowLoad: value,
      }),
    );
  }

  function setMinGlassThickness(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSystemRoof(editedTestPhaseSystem, {
        minGlassThickness: value,
      }),
    );
  }

  return (
    <FormLayout additionalClass="test-phases__edit-phases-row-system">
      <div className="test-phases__function-security">
        <FormLayoutSubgroupTitle>
          Klassifizierung der Funktionssicherheit
        </FormLayoutSubgroupTitle>
        <AdminRadioButton
          checked={
            Funktionssicherheit.RE1000 === editedTestPhaseSystem.klassFunk
          }
          name="Re 1000"
          onChange={() => setKlassFunk(Funktionssicherheit.RE1000)}
        />
        <AdminRadioButton
          checked={
            Funktionssicherheit.RE1000PLUS === editedTestPhaseSystem.klassFunk
          }
          name="Re 1000 + Le 10.000"
          onChange={() => setKlassFunk(Funktionssicherheit.RE1000PLUS)}
        />
      </div>

      <div className="test-phases__temperature">
        <FormLayoutSubgroupTitle>Umgebungstemperatur</FormLayoutSubgroupTitle>
        <AdminRadioButton
          checked={UmgebungsTemp.ZERO === editedTestPhaseSystem.umgebungstemp}
          name="0°"
          onChange={() => setTemperature(UmgebungsTemp.ZERO)}
        />
        <AdminRadioButton
          checked={
            UmgebungsTemp.FIVE_UNDER_ZERO ===
            editedTestPhaseSystem.umgebungstemp
          }
          name="-5°"
          onChange={() => setTemperature(UmgebungsTemp.FIVE_UNDER_ZERO)}
        />
        <AdminRadioButton
          checked={
            UmgebungsTemp.TEN_UNDER_ZERO === editedTestPhaseSystem.umgebungstemp
          }
          name="-10°"
          onChange={() => setTemperature(UmgebungsTemp.TEN_UNDER_ZERO)}
        />
        <AdminRadioButton
          checked={
            UmgebungsTemp.FIFTEEN_UNDER_ZERO ===
            editedTestPhaseSystem.umgebungstemp
          }
          name="-15°"
          onChange={() => setTemperature(UmgebungsTemp.FIFTEEN_UNDER_ZERO)}
        />
      </div>

      <div className="test-phases__heat">
        <FormLayoutSubgroupTitle>Wärmebeständigkeit</FormLayoutSubgroupTitle>
        <AdminRadioButton
          checked={
            Waermebestaendigkeit.B300E === editedTestPhaseSystem.klassWaermebest
          }
          name={Waermebestaendigkeit.B300E}
          onChange={() => setHeat(Waermebestaendigkeit.B300E)}
        />
        <AdminRadioButton
          checked={
            Waermebestaendigkeit.B300F === editedTestPhaseSystem.klassWaermebest
          }
          name={Waermebestaendigkeit.B300F}
          onChange={() => setHeat(Waermebestaendigkeit.B300F)}
        />
        <AdminRadioButton
          checked={
            Waermebestaendigkeit.N_P_D === editedTestPhaseSystem.klassWaermebest
          }
          name={Waermebestaendigkeit.N_P_D}
          onChange={() => setHeat(Waermebestaendigkeit.N_P_D)}
        />
        <AdminRadioButton
          checked={
            Waermebestaendigkeit.B300_N_P_D ===
            editedTestPhaseSystem.klassWaermebest
          }
          name={Waermebestaendigkeit.B300_N_P_D}
          onChange={() => setHeat(Waermebestaendigkeit.B300_N_P_D)}
        />
      </div>
      <FormLayout additionalClass="test-phases__loads" inline={true}>
        <FormLayoutElement rowStart={1} columnStart={1} columnWidth={6}>
          <FormLayoutSubgroupTitle>Max. Windlast</FormLayoutSubgroupTitle>
          <InputFieldNumber
            value={editedTestPhaseSystem.maxWindLoad}
            onChange={setMaxWindLoad}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={6}>
          <FormLayoutSubgroupTitle>Max. Schneelast</FormLayoutSubgroupTitle>
          <InputFieldNumber
            value={editedTestPhaseSystem.maxSnowLoad}
            onChange={setMaxSnowLoad}
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={1} columnWidth={6}>
          <FormLayoutSubgroupTitle>
            min. Glasstärke (mm)*
          </FormLayoutSubgroupTitle>
          <InputFieldNumber
            value={editedTestPhaseSystem.minGlassThickness}
            onChange={setMinGlassThickness}
          />
        </FormLayoutElement>
      </FormLayout>
    </FormLayout>
  );
};

const TestPhasesDataDialogFurtherInformationSeries: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const editedTestPhaseSeries = useSelector<
    AdminState,
    TestPhaseSeriesRoofWithOptionalId
  >(state => state.adminRoof.editedTestPhaseSeries!);
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [
    applicationOverviewsDialogIsShown,
    setApplicationOverviewsDialogIsShown,
  ] = useState(false);
  const [windLoadsDialogIsShown, setWindLoadsDialogIsShown] = useState(false);
  const frameProfilesRoofForSeries = useSelector<
    AdminState,
    FrameProfileRoof[]
  >(state =>
    state.adminRoof.frameProfiles.filter(
      f => f.seriesId === editedTestPhaseSeries?.series.id && f.active,
    ),
  );
  const sashProfilesRoofForSeries = useSelector<AdminState, SashProfileRoof[]>(
    state =>
      state.adminRoof.sashProfiles.filter(
        f => f.seriesId === editedTestPhaseSeries?.series.id && f.active,
      ),
  );
  const [editingSnowLoad, setEditingSnowLoad] = useState(false);

  function editApplicationOverviews(): void {
    dispatch(
      updateEditedTestPhaseApplicationOverviews(
        editedTestPhaseSeries.applicationOverviews,
      ),
    );
    setApplicationOverviewsDialogIsShown(true);
  }

  function editWindLoads(): void {
    dispatch(updateEditedTestPhaseLoads(editedTestPhaseSeries.windLoads));
    setWindLoadsDialogIsShown(true);
    setEditingSnowLoad(false);
  }

  function editSnowLoads(): void {
    dispatch(updateEditedTestPhaseLoads(editedTestPhaseSeries.snowLoads));
    setWindLoadsDialogIsShown(true);
    setEditingSnowLoad(true);
  }

  if (!editedTestPhaseSeries) {
    return null;
  }

  function setMaterial(value: SelectOption<string>): void {
    dispatch(
      updateEditedTestPhaseSeriesRoof(editedTestPhaseSeries, {
        material: value.value,
      }),
    );
  }

  function setMinGepruefteFluegelbreite(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSeriesRoof(editedTestPhaseSeries, {
        minGepruefteFluegelbreite: value,
      }),
    );
  }

  function setMaxGepruefteFluegelbreite(value: number | null): void {
    dispatch(
      updateEditedTestPhaseSeriesRoof(editedTestPhaseSeries, {
        maxGepruefteFluegelbreite: value,
      }),
    );
  }

  function setInCertificate(value: boolean): void {
    dispatch(
      updateEditedTestPhaseSeriesRoof(editedTestPhaseSeries, {
        inCertificate: value,
      }),
    );
  }

  return (
    <FormLayout>
      <FormLayoutElement rowStart={1} columnStart={1} columnWidth={2}>
        <FormLayout>
          <FormLayoutElement rowStart={1} columnStart={1} columnWidth={6}>
            <SelectField
              label="Material *"
              value={
                editedTestPhaseSeries.material
                  ? {
                      value: editedTestPhaseSeries.material,
                      label:
                        SERIES_MATERIAL_OPTIONS[editedTestPhaseSeries.material],
                    }
                  : undefined
              }
              action={setMaterial}
              options={Object.entries(SERIES_MATERIAL_OPTIONS).map(values =>
                selectFieldValue(values[1], values[0]),
              )}
              name="seriesOfBasicProfile"
              searchable={true}
              placeholder={UiConstants.SELECT_SERIES}
            />
          </FormLayoutElement>
          <FormLayoutElement rowStart={2} columnStart={1} columnWidth={6}>
            <FieldLabel>geprüfte Flügelbreite *</FieldLabel>
            <InputFieldNumber
              label="Min."
              placeholder="z.B 42"
              value={editedTestPhaseSeries.minGepruefteFluegelbreite}
              additionalClass="test-phases__input-1"
              onChange={setMinGepruefteFluegelbreite}
            />
            <InputFieldNumber
              label="Max."
              placeholder=""
              value={editedTestPhaseSeries.maxGepruefteFluegelbreite}
              additionalClass="test-phases__input-2"
              onChange={setMaxGepruefteFluegelbreite}
            />
          </FormLayoutElement>

          <FormLayoutElement rowStart={3} columnStart={1} columnWidth={6}>
            <div className="test-phases__in-certificate">
              <Switch
                labelText="im Zertifikat vorhanden"
                turnedOn={editedTestPhaseSeries.inCertificate}
                onChange={setInCertificate}
              />
            </div>
          </FormLayoutElement>

          <FormLayoutElement rowStart={4} columnStart={1} columnWidth={3}>
            <Button
              labelText="Anwendungsübersichten"
              action={editApplicationOverviews}
              type={ButtonType.FRAME}
              additionalClass="test-phases__button-roof"
            />
          </FormLayoutElement>
          <FormLayoutElement rowStart={5} columnStart={1} columnWidth={3}>
            <Button
              labelText="Windlast (Pa)"
              action={editWindLoads}
              type={ButtonType.FRAME}
              additionalClass="test-phases__button-roof"
            />
          </FormLayoutElement>
          <FormLayoutElement rowStart={5} columnStart={4} columnWidth={3}>
            <Button
              labelText="Schneelast (Pa)"
              action={editSnowLoads}
              type={ButtonType.FRAME}
              additionalClass="test-phases__button-roof"
            />
          </FormLayoutElement>
          {applicationOverviewsDialogIsShown && (
            <ApplicationOverviewsDialog
              shown={applicationOverviewsDialogIsShown}
              setShown={setApplicationOverviewsDialogIsShown}
              editedTestPhaseSeries={editedTestPhaseSeries}
              updateEditedSeries={(editedSeries, values) => {
                dispatch(
                  updateEditedTestPhaseSeriesRoof(
                    editedTestPhaseSeries,
                    values,
                  ),
                );
              }}
            />
          )}
          {windLoadsDialogIsShown && (
            <WindOrSnowLoadsDialog
              shown={windLoadsDialogIsShown}
              setShown={setWindLoadsDialogIsShown}
              editedTestPhaseSeries={editedTestPhaseSeries}
              updateEditedSeries={(editedSeries, values) =>
                dispatch(
                  updateEditedTestPhaseSeriesRoof(
                    editedTestPhaseSeries,
                    values,
                  ),
                )
              }
              snowLoad={editingSnowLoad}
            />
          )}
        </FormLayout>
      </FormLayoutElement>
      <FormLayoutElement rowStart={1} columnStart={3} columnWidth={4}>
        <FormLayout>
          <FormLayoutElement rowStart={1} columnStart={1} columnWidth={3}>
            <AssignSelectGroup
              multipleSelectAllowed={true}
              headings={{
                componentHeadingWithoutDialog: 'Blendrahmen zuordnen',
                headingLeftTable: 'nicht ausgewählt',
                headingRightTable: 'ausgewählt',
              }}
              componentClass=""
              addElements={(frameProfilesRoof: FrameProfileRoof[]) =>
                dispatch(
                  updateEditedTestPhaseSeriesRoof(editedTestPhaseSeries, {
                    frameProfiles: [
                      ...editedTestPhaseSeries.frameProfiles,
                      ...frameProfilesRoof,
                    ],
                  }),
                )
              }
              removeElements={(frameProfilesRoof: FrameProfileRoof[]) =>
                dispatch(
                  updateEditedTestPhaseSeriesRoof(editedTestPhaseSeries, {
                    frameProfiles: editedTestPhaseSeries.frameProfiles.filter(
                      f2 => !frameProfilesRoof.includes(f2),
                    ),
                  }),
                )
              }
              selectedElements={editedTestPhaseSeries.frameProfiles}
              deselectedElements={frameProfilesRoofForSeries.filter(
                f =>
                  !editedTestPhaseSeries.frameProfiles
                    .map(f2 => f2.id)
                    .includes(f.id),
              )}
              displayNameExtractor={(f: FrameProfileRoof) => f.artNr}
              backgroundWhite={true}
            />
          </FormLayoutElement>
          <FormLayoutElement rowStart={1} columnStart={4} columnWidth={3}>
            <AssignSelectGroup
              multipleSelectAllowed={true}
              headings={{
                componentHeadingWithoutDialog: 'Flügelprofil zuordnen',
                headingLeftTable: 'nicht ausgewählt',
                headingRightTable: 'ausgewählt',
              }}
              componentClass=""
              addElements={(sashProfilesRoof: SashProfileRoof[]) =>
                dispatch(
                  updateEditedTestPhaseSeriesRoof(editedTestPhaseSeries, {
                    sashProfiles: [
                      ...editedTestPhaseSeries.sashProfiles,
                      ...sashProfilesRoof,
                    ],
                  }),
                )
              }
              removeElements={(sashProfilesRoof: SashProfileRoof[]) =>
                dispatch(
                  updateEditedTestPhaseSeriesRoof(editedTestPhaseSeries, {
                    sashProfiles: editedTestPhaseSeries.sashProfiles.filter(
                      f2 => !sashProfilesRoof.includes(f2),
                    ),
                  }),
                )
              }
              selectedElements={editedTestPhaseSeries.sashProfiles}
              deselectedElements={sashProfilesRoofForSeries.filter(
                f =>
                  !editedTestPhaseSeries.sashProfiles
                    .map(f2 => f2.id)
                    .includes(f.id),
              )}
              displayNameExtractor={(f: SashProfileRoof) => f.artNr}
              backgroundWhite={true}
            />
          </FormLayoutElement>
        </FormLayout>
      </FormLayoutElement>
    </FormLayout>
  );
};

const TestPhasesDataDialogFurtherInformationApplication: FC<
  React.PropsWithChildren<unknown>
> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedTestPhaseApplication = useSelector<
    AdminState,
    TestPhaseApplicationRoofWithOptionalId
  >(state => state.adminRoof.editedTestPhaseApplication!);

  if (!editedTestPhaseApplication) {
    return null;
  }

  function updateMaxSashWeight(value: number | null): void {
    dispatch(
      updateEditedTestPhaseApplicationRoof(editedTestPhaseApplication, {
        maxSashWeight: value,
      }),
    );
  }

  function updateSashFrameDistanceMin(value: number | null): void {
    dispatch(
      updateEditedTestPhaseApplicationRoof(editedTestPhaseApplication, {
        sashFrameDistanceMin: value,
      }),
    );
  }

  function updateSashFrameDistanceMax(value: number | null): void {
    dispatch(
      updateEditedTestPhaseApplicationRoof(editedTestPhaseApplication, {
        sashFrameDistanceMax: value,
      }),
    );
  }

  function applicationSetInCertificate(value: boolean): void {
    dispatch(
      updateEditedTestPhaseApplicationRoof(editedTestPhaseApplication, {
        inCertificate: value,
      }),
    );
  }

  return (
    <FormLayout additionalClass="">
      <FormLayoutElement rowStart={1} columnWidth={1} columnStart={1}>
        <InputFieldNumber
          label="Max. Flügelgewicht (kg) *"
          placeholder="z.B. 42"
          value={editedTestPhaseApplication.maxSashWeight}
          onChange={updateMaxSashWeight}
          integer
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={2} columnWidth={1} columnStart={1}>
        <InputFieldNumber
          label="Min. Flügelrahmenabstand (mm)"
          placeholder="z.B. 42"
          value={editedTestPhaseApplication.sashFrameDistanceMin}
          onChange={updateSashFrameDistanceMin}
          integer
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={3} columnStart={1} columnWidth={1}>
        <InputFieldNumber
          label="Max. Flügelrahmenabstand (mm)"
          placeholder="z.B. 42"
          value={editedTestPhaseApplication.sashFrameDistanceMax}
          additionalClass="test-phases__input-1"
          onChange={updateSashFrameDistanceMax}
          integer
        />
      </FormLayoutElement>

      <div className="test-phases__in-certificate">
        <Switch
          labelText="im Zertifikat vorhanden"
          turnedOn={
            useEditedTestPhaseSeriesRoof().inCertificate &&
            editedTestPhaseApplication.inCertificate
          }
          onChange={applicationSetInCertificate}
          disabled={!useEditedTestPhaseSeriesRoof().inCertificate}
        />
      </div>
    </FormLayout>
  );
};

function NewTestPhaseWinddeflectorConfigurationDialog(props: {
  setDialogIsShown: (b: boolean) => void;
  dialogIsShown: boolean;
}): ReactElement {
  const [
    largerOrEqualThanAngleOfInstallation,
    setLargerThanAngleOfInstallation,
  ] = useNumberState();
  const [
    smallerOrEqualThanAngleOfInstallation,
    setSmallerOrEqualThanAngleOfInstallation,
  ] = useNumberState();
  const [numberOfWindDeflectors, setNumberOfWindDeflectors] = useNumberState();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedTestPhaseAerodynamicCase = useSelector<
    AdminState,
    TestPhaseAerodynamicCaseRoofWithOptionalId
  >(state => state.adminRoof.editedTestPhaseAerodynamicCase!);

  function addWinddeflectorConfiguration(): void {
    dispatch(
      updateEditedTestPhaseAerodynamicCaseRoof(editedTestPhaseAerodynamicCase, {
        windDeflectorConfiguration: [
          ...editedTestPhaseAerodynamicCase.windDeflectorConfiguration,
          {
            largerOrEqualThanAngleOfInstallation:
              largerOrEqualThanAngleOfInstallation!,
            smallerOrEqualThanAngleOfInstallation:
              smallerOrEqualThanAngleOfInstallation!,
            numberOfWindDeflectors: numberOfWindDeflectors!,
          },
        ],
      }),
    );
    props.setDialogIsShown(false);
  }

  function valid(): boolean {
    const correctOrder =
      !largerOrEqualThanAngleOfInstallation ||
      !smallerOrEqualThanAngleOfInstallation ||
      largerOrEqualThanAngleOfInstallation <=
        smallerOrEqualThanAngleOfInstallation;

    return !!(
      (largerOrEqualThanAngleOfInstallation ||
        smallerOrEqualThanAngleOfInstallation) &&
      numberOfWindDeflectors &&
      correctOrder
    );
  }

  return (
    <Dialog
      componentClass=""
      headingText="Neue Windleitwanddefinition"
      dialogIsShown={props.dialogIsShown}
      setDialogIsShown={props.setDialogIsShown}
      footerProps={{
        saveAction: valid() ? addWinddeflectorConfiguration : undefined,
        primaryActionLabelText: 'Hinzufügen',
        cancelAction: () => props.setDialogIsShown(false),
      }}
      nested
    >
      <FormLayout>
        <FormLayoutElement rowStart={1} columnStart={1} columnWidth={6}>
          <FieldLabel>Einbauwinkel</FieldLabel>
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={1} columnWidth={1}>
          <InputFieldNumber
            label="von"
            value={largerOrEqualThanAngleOfInstallation}
            onChange={setLargerThanAngleOfInstallation}
            placeholder="z.B. 42"
            integer
            notNegative
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={2} columnStart={2} columnWidth={1}>
          <InputFieldNumber
            label="bis"
            value={smallerOrEqualThanAngleOfInstallation}
            onChange={setSmallerOrEqualThanAngleOfInstallation}
            placeholder="z.B. 42"
            integer
            notNegative
          />
        </FormLayoutElement>
        <FormLayoutElement rowStart={3} columnStart={1} columnWidth={6}>
          <InputFieldNumber
            label="Windleitwände"
            value={numberOfWindDeflectors}
            onChange={setNumberOfWindDeflectors}
            placeholder="z.B. 42"
            integer
            positive
          />
        </FormLayoutElement>
      </FormLayout>
    </Dialog>
  );
}

function TestPhasesDataDialogFurtherInformationAerodyamicCase(): ReactElement | null {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const editedTestPhaseAerodynamicCase = useSelector<
    AdminState,
    TestPhaseAerodynamicCaseRoofWithOptionalId
  >(state => state.adminRoof.editedTestPhaseAerodynamicCase!);
  const editedApplication = useSelector<
    AdminState,
    TestPhaseApplicationRoofWithOptionalId | undefined
  >(state => state.adminRoof.editedTestPhaseApplication);

  const allCvCurves = useSelector<AdminState, CVKurve[]>(
    state => state.adminRoof.cvKurven,
  );
  const [dialogIsShown, setDialogIsShown] = useState(false);

  if (!editedTestPhaseAerodynamicCase) {
    return null;
  }

  function cvCurvesToSelect(): SelectOption<string>[] {
    const options: SelectOption<string>[] = [];
    allCvCurves.forEach(curve => {
      if (
        editedApplication?.doubleFlapSideHung &&
        !curve.forDoubleFlapApplicationSideHung
      ) {
        return;
      }

      if (
        editedApplication?.doubleFlapBottomOrTopHung &&
        !curve.forDoubleFlapApplicationTopOrBottomHung
      ) {
        return;
      }

      options.push({ value: curve.id.toString(), label: curve.name });
    });
    return options;
  }

  function findCvCurveById(id: number): CVKurve | undefined {
    return allCvCurves.find(cvCurve => cvCurve.id === id);
  }

  function updateAerodynamicCaseCvCurve(value: number): void {
    dispatch(
      updateEditedTestPhaseAerodynamicCaseRoof(editedTestPhaseAerodynamicCase, {
        cvCurve: findCvCurveById(value),
      }),
    );
  }

  function updateEditedTestPhaseAerodynamicCase<V>(
    key: keyof TestPhaseAerodynamicCaseRoofWithOptionalId,
  ): (value: V) => void {
    return value =>
      dispatch(
        updateEditedTestPhaseAerodynamicCaseRoof(
          editedTestPhaseAerodynamicCase,
          {
            [key]: value,
          },
        ),
      );
  }

  function deleteDefinition(
    definition: TestPhaseWinddeflectorConfiguration,
  ): void {
    updateEditedTestPhaseAerodynamicCase('windDeflectorConfiguration')(
      editedTestPhaseAerodynamicCase.windDeflectorConfiguration.filter(
        editedDefinition => editedDefinition !== definition,
      ),
    );
  }

  return (
    <FormLayout>
      <FormLayoutElement rowStart={1} columnStart={1} columnWidth={2}>
        <SelectField
          label="CV Kurve *"
          key={editedTestPhaseAerodynamicCase?.cvCurve?.id.toString()}
          value={
            editedTestPhaseAerodynamicCase.cvCurve
              ? {
                  value: editedTestPhaseAerodynamicCase.cvCurve.id.toString(),
                  label: findCvCurveById(
                    editedTestPhaseAerodynamicCase.cvCurve.id,
                  )?.name,
                }
              : undefined
          }
          action={newValue => {
            updateAerodynamicCaseCvCurve(+newValue.value);
          }}
          options={cvCurvesToSelect()}
          name=""
          searchable={true}
          placeholder="z.B. cv42"
          menuPlacement={MenuPlacement.TOP}
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={2} columnStart={1} columnWidth={2}>
        <InputFieldNumber
          label="maximale Flügelfläche (m²) *"
          placeholder="z.B. 42"
          value={editedTestPhaseAerodynamicCase.maxFluegelflaeche}
          additionalClass="test-phases__input-1"
          onChange={updateEditedTestPhaseAerodynamicCase('maxFluegelflaeche')}
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={3} columnStart={1} columnWidth={2}>
        <InputFieldNumber
          label="maximales Verhältnis Flügelbreite zu Flügelhöhe"
          placeholder="z.B. 42"
          value={editedTestPhaseAerodynamicCase.maximumRatioWidthToHeight}
          additionalClass=""
          onChange={updateEditedTestPhaseAerodynamicCase(
            'maximumRatioWidthToHeight',
          )}
        />
      </FormLayoutElement>
      <FormLayoutElement rowStart={4} columnStart={1} columnWidth={2}>
        <FieldLabel>Flügelhöhe (mm)</FieldLabel>
        <FormLayout inline>
          <FormLayoutElement rowStart={1} columnStart={1} columnWidth={3}>
            <InputFieldNumber
              label="von"
              placeholder="z.B. 42"
              value={editedTestPhaseAerodynamicCase.largerOrEqualSashHeight}
              additionalClass=""
              onChange={updateEditedTestPhaseAerodynamicCase(
                'largerOrEqualSashHeight',
              )}
              integer
            />
          </FormLayoutElement>
          <FormLayoutElement rowStart={1} columnStart={4} columnWidth={3}>
            <InputFieldNumber
              label="bis"
              placeholder="z.B. 42"
              value={editedTestPhaseAerodynamicCase.smallerOrEqualSashHeight}
              additionalClass=""
              onChange={updateEditedTestPhaseAerodynamicCase(
                'smallerOrEqualSashHeight',
              )}
              integer
            />
          </FormLayoutElement>
        </FormLayout>
        <Hint>
          Die Flügelhöhe bezeichnet in allen Anwendungsfällen die geometrische
          Dimension, die sich auch Sicht eines Betrachters am Boden vertikal
          erstreckt.
        </Hint>
      </FormLayoutElement>
      <FormLayoutElement
        rowStart={1}
        columnStart={3}
        columnWidth={3}
        rowHeight={3}
      >
        <FieldLabel>Windleitwandkonfiguration</FieldLabel>
        <Table>
          <TableHeader>
            <th>&gt;= Einbauwinkel</th>
            <th>&lt;= Einbauwinkel</th>
            <th>Anzahl der Windleitwände</th>
            <th></th>
          </TableHeader>
          <tbody>
            {editedTestPhaseAerodynamicCase.windDeflectorConfiguration.map(
              (definition, index) => (
                <TableRow
                  key={
                    definition.numberOfWindDeflectors +
                    definition.smallerOrEqualThanAngleOfInstallation +
                    definition.largerOrEqualThanAngleOfInstallation
                  }
                >
                  <td>{definition.largerOrEqualThanAngleOfInstallation}</td>
                  <td>{definition.smallerOrEqualThanAngleOfInstallation}</td>
                  <td>{definition.numberOfWindDeflectors}</td>
                  <td>
                    <Button
                      action={() => deleteDefinition(definition)}
                      label={UiConstants.DELETE}
                      type={ButtonType.SECONDARY}
                    />
                  </td>
                </TableRow>
              ),
            )}
          </tbody>
        </Table>
        <div className="test-phases__add-winddeflector-configuration">
          <Button
            action={() => setDialogIsShown(true)}
            label={UiConstants.NEW_ENTRY}
            type={ButtonType.SECONDARY}
          />
        </div>
        <NewTestPhaseWinddeflectorConfigurationDialog
          key={editedTestPhaseAerodynamicCase.windDeflectorConfiguration.length.toString()}
          dialogIsShown={dialogIsShown}
          setDialogIsShown={setDialogIsShown}
        />
      </FormLayoutElement>
    </FormLayout>
  );
}

interface EditTestPhasesRowProps {
  activeSection: ActiveFurtherInformationUiSection;
  setActiveSection: (section: ActiveFurtherInformationUiSection) => void;
}

const EditTestPhasesRow: FC<React.PropsWithChildren<EditTestPhasesRowProps>> = (
  props: EditTestPhasesRowProps,
) => {
  return (
    <div className="test-phases__edit-phases-row">
      {props.activeSection === ActiveFurtherInformationUiSection.SYSTEM && (
        <TestPhasesDataDialogFurtherInformationSystems />
      )}

      {props.activeSection === ActiveFurtherInformationUiSection.SERIES && (
        <TestPhasesDataDialogFurtherInformationSeries />
      )}
      {props.activeSection ===
        ActiveFurtherInformationUiSection.APPLICATION && (
        <TestPhasesDataDialogFurtherInformationApplication />
      )}
      {props.activeSection ===
        ActiveFurtherInformationUiSection.AERODYNAMIC_CASE && (
        <TestPhasesDataDialogFurtherInformationAerodyamicCase />
      )}
    </div>
  );
};

interface AdminTestPhaseDialogProps {
  dialogIsShown: boolean;
  setDialogIsShown: (b: boolean) => void;
}

const AdminTestPhaseDialog: FC<
  React.PropsWithChildren<AdminTestPhaseDialogProps>
> = (props: AdminTestPhaseDialogProps) => {
  const editableTestPhase = useEditedTestPhaseRoof();
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const mandatoryFieldsFilledOrInactive = useSelector<AdminState>(
    state =>
      state.adminRoof.editedTestPhaseValid ||
      state.adminRoof.editedTestPhase?.active === ACTIVE_STATE.INACTIVE,
  );

  function generateTestPhase(): void {
    dispatch(createTestPhaseRoof());
  }

  function editTestPhase(): void {
    if (!editableTestPhase) {
      return;
    }
    dispatch(changeTestPhaseRoof(editableTestPhase));
  }

  return (
    <Dialog
      isConfirmation={true}
      setDialogIsShown={props.setDialogIsShown}
      dialogIsShown={props.dialogIsShown}
      headingText={
        editableTestPhase?.id
          ? `Prüfphasephase ${editableTestPhase.name} bearbeiten`
          : 'Neue Prüfphasephase anlegen'
      }
      componentClass="full-view-dialog"
      key={props.dialogIsShown.toString()}
      footerProps={{
        notTranslated: true,
        cancelAction: () => {
          props.setDialogIsShown(false);
        },
        saveAction: mandatoryFieldsFilledOrInactive
          ? () => {
              editableTestPhase.id ? editTestPhase() : generateTestPhase();
              props.setDialogIsShown(false);
            }
          : undefined,
        primaryActionLabelText: editableTestPhase?.id
          ? UiConstants.SAVE
          : UiConstants.ADD,
      }}
    >
      <TestPhasesDataDialogTabNav />
      {editableTestPhase && (
        <Routes>
          <Route path="/basic/*" element={<TestPhasesDataDialogBasic />} />
          <Route
            path="/further-information/*"
            element={<TestPhasesDataDialogFurtherInformation />}
          />
          <Route
            path="/general-dependencies/*"
            element={<TestPhasesDataDialogGeneralDependencies />}
          />
        </Routes>
      )}
    </Dialog>
  );
};

const TestPhasesDataViewRoof: FC<React.PropsWithChildren<unknown>> = () => {
  const dispatch: AdminThunkDispatch<AnyAction> = useDispatch();
  const [dialogIsShown, setDialogIsShown] = useState(false);
  const testPhases = useSelector<AdminState, TestPhaseRoof[]>(
    s => s.adminRoof.testPhases,
  );
  const testPhasesRef = useRef<TestPhaseRoof[] | undefined>();

  if (!testPhasesRef.current) {
    testPhasesRef.current = testPhases;
  }

  if (testPhasesRef.current !== testPhases) {
    testPhasesRef.current = testPhases;
  }

  const [indexOfFirstPageElement, setIndexOfFirstPageElement] = useState(0);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [filterActive, setFilterActive, searchResult] = useAdminSearch(
    testPhases,
    searchString,
    ['name'],
  );

  function enableEditTestPhase(): void {
    setDialogIsShown(true);
  }

  function getCurrentTableContent(): TestPhaseRoof[] {
    return searchResult.slice(
      indexOfFirstPageElement,
      indexOfFirstPageElement + 20,
    );
  }

  function triggerCreationMode(): void {
    dispatch(initializeNewEditableTestPhaseRoof());
    setDialogIsShown(true);
  }

  function setDialogIsShownWithReset(dialogIsShown: boolean): void {
    if (!dialogIsShown) {
      dispatch(updateEditedTestPhaseSystemRoof(undefined));
      dispatch(updateEditedTestPhaseSeriesRoof(undefined));
      dispatch(updateEditedTestPhaseApplicationRoof(undefined));
    }

    setDialogIsShown(dialogIsShown);
  }

  return (
    <div>
      <AdminTestPhaseDialog
        dialogIsShown={dialogIsShown}
        setDialogIsShown={setDialogIsShownWithReset}
      />

      <div className="sub-header">
        <div className="sub-header__title">Prüfphasen</div>

        <SearchField
          setSearchString={setSearchString}
          searchString={searchString}
          placeholderText="Prüfphasen suchen..."
          small={true}
          setFilterActive={setFilterActive}
          filterActive={filterActive}
        />
        <button
          className="sub-header__button sub-header__button--add"
          onClick={() => triggerCreationMode()}
        >
          {UiConstants.NEW_ENTRY}
        </button>
      </div>
      <div>
        <Table subNavigation={false}>
          <TableHeader>
            <th>Name</th>
            <th>Aktion</th>
          </TableHeader>

          <tbody>
            {getCurrentTableContent().map(testPhase => (
              <TableRow key={testPhase.id}>
                <td>{testPhase.name}</td>
                <td>
                  <button
                    onClick={() => {
                      enableEditTestPhase();
                      dispatch(selectEditableTestPhaseRoof(testPhase));
                    }}
                  >
                    {UiConstants.EDIT}
                  </button>
                  <button
                    className="test-phases__copy-button"
                    onClick={() => {
                      if (
                        confirm(
                          `Soll die Prüfphase "${testPhase.name}" wirklich kopiert werden?`,
                        )
                      ) {
                        dispatch(copyTestPhaseRoof(testPhase.id));
                      }
                    }}
                  >
                    Kopieren
                  </button>
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        searchString={searchString}
        numberOfPages={searchResult.length}
        page={page}
        setPage={setPage}
        indexOfFirstPageElement={indexOfFirstPageElement}
        setIndexOfFirstPageElement={setIndexOfFirstPageElement}
      />
    </div>
  );
};

export default TestPhasesDataViewRoof;
