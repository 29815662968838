import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action, ActionCreator, AnyAction } from 'redux';
import { IState } from './store';
import {
  calculating,
  CalculatingAction,
  deselectDriveAndSaveAction,
  FinishedCalculation,
  finishedCalculation,
  markDriveAction,
  resetConsoleSets,
  setHighlightedConsoleSet,
  SetHighlightedConsoleSetAction,
  StoreMarkedDriveAction,
  setSelectedBaseProfile,
  SetSelectedBaseProfileAction,
  SetSelectedConsoleSetAction,
  setSelectedExchangeProfile,
  SetSelectedExchangeProfileAction,
  setSelectedFrameProfile,
  SetSelectedFrameProfileAction,
  setSelectedSashProfile,
  SetSelectedSashProfileAction,
  setSelectedSeries,
  SetSelectedSeriesAction,
  setSelectedSystemSeries,
  SetSelectedSystemSeriesAction,
  storeSelectedConsoleSet,
  StoreSelectedNRWGConsoleSetAction,
  switchPage,
  UpdateExpand,
  UpdateHintConfirmation,
  updateInputFromLogikal,
  UpdateInputFromLogikal,
} from './uiStateActions';
import {
  ResetCalculationResult,
  resetCalculationResults,
  UpdateCalculationResult,
  updateCalculationResult,
} from './calculationResultActions';
import {
  getGeomtricDesmoking,
  ResetDesmoking,
  UpdateAerodynamicDesmokingData,
  UpdateGeometricDesmokingData,
} from './desmokingResultActions';

import {
  getSuitableConsoleSets,
  SetConsoleDocuments,
  SetDefaultConsoleSetAction,
  SetDocumentsListsResult,
  SetDriveDocumentsAction,
  SetLockingConsoleDocuments,
  SetLockingDocuments,
  SetNrwgManualDocuments,
  setSuitableConsoleSets,
  StoreNRWGConsoleSetCandidatesAction,
  StoreSuitableConsoleSetsAction,
} from './staticDataActions';
import { getSystemSeriesResult } from './profileDataActions';
import {
  BaseProfile,
  ExchangeProfile,
  FrameProfile,
  SashProfile,
  SystemSeries,
} from './profileDataReducer';
import {
  CONSOLE_PARAMETERS,
  DESMOKING_PARAMETERS,
  getDriveCalculationParameters,
  notAllRequiredCalculationParametersDefined,
  REQUIRED_CALCULATION_PARAMETERS_NRWG,
  ValueKey,
} from './valueKey';
import { Window } from './projectsReducer';

import { saveParameterStateInSelectedWindow } from './projectsActions';
import {
  EMPTY_PARAMETERS_STATES,
  LiteralParameterValue,
  ParametersState,
} from './parametersReducer';
import { fetchAndStore, RequestTypes } from './httpClient';
import { Parameters } from '../components/Parameters/ParameterValue';
import { getMarkedDrive, getSelectedWindow } from '../hooks/selectorHooks';
import {
  getOrResetWindDeflectors,
  getPerformanceClasses,
  getRanges,
  getTestSearchOptions,
  getValidation,
  StoreWindDeflectors,
  storeWindDeflectors,
} from './nrwgActions';
import {
  AssemblyType,
  DrivePosition,
  DriveType,
  FillType,
  LockingDrive,
  OpeningDirection,
  OpeningType,
  RangeOfApplication,
  Voltage,
} from './constants';
import {
  CalculationResult,
  Drive,
  sortByErrorCount,
} from './calculationResultReducer';
import _, { isEmpty, pickBy } from 'lodash';
import { Pages } from './uiStateReducer';
import { showSnackBar } from './globalUiStateActions';
import { MessageDescriptor, useIntl } from 'react-intl';

export interface UpdateStore extends Action<'UPDATE_STORE'> {
  payload: {
    valueKeyName: string;
    value: LiteralParameterValue;
    unit?: string;
  };
}

export interface UpdateAngle extends Action<'UPDATE_ANGLE'> {
  value: number | undefined;
}

export interface ChangeParametersState
  extends Action<'CHANGE_PARAMETERS_STATE'> {
  calculationParameters: ParametersState;
}

export interface UpdateStroke extends Action<'UPDATE_STROKE'> {
  value: number | undefined;
}

export type ChangeCalculationAction = ThunkAction<
  Promise<void>,
  IState,
  void,
  UpdateCalculationResult
>;

let fetchDelayTimerId: number;
let abortController: AbortController;

export type Dispatch = ThunkDispatch<
  IState,
  void,
  // | UpdateStroke
  // | UpdateAngle
  | UpdateStore
  | CalculatingAction
  | FinishedCalculation
  | UpdateCalculationResult
  | StoreMarkedDriveAction
  | ResetCalculationResult
  | ResetDesmoking
  | SetDriveDocumentsAction
  | SetDefaultConsoleSetAction
  | SetSelectedConsoleSetAction
  | SetHighlightedConsoleSetAction
  | StoreSuitableConsoleSetsAction
  | UpdateGeometricDesmokingData
  | UpdateExpand
  | UpdateHintConfirmation
  | SetConsoleDocuments
  | SetLockingDocuments
  | SetLockingConsoleDocuments
  | SetSelectedSystemSeriesAction
  | SetSelectedSeriesAction
  | SetSelectedSashProfileAction
  | SetSelectedFrameProfileAction
  | SetSelectedExchangeProfileAction
  | SetSelectedBaseProfileAction
  | ChangeParametersState
  | UpdateAerodynamicDesmokingData
  | StoreNRWGConsoleSetCandidatesAction
  | SetDocumentsListsResult
  | SetNrwgManualDocuments
  | StoreWindDeflectors
  | StoreSelectedNRWGConsoleSetAction
>;

export function updateStore(
  valueKeyName: string,
  value: LiteralParameterValue,
): UpdateStore {
  return {
    type: 'UPDATE_STORE',
    payload: { valueKeyName, value },
  };
}

function changesValueKey(
  key: ValueKey,
  params: Partial<Record<ValueKey, LiteralParameterValue>>,
): boolean {
  return Object.keys(params).includes(key);
}

export function changeCalculationParameter(
  key: ValueKey,
  value: LiteralParameterValue,
  keepSelectedDriveWhenRecalculating?: boolean,
): ChangeCalculationAction {
  return changeCalculationParameterBulk(
    { [key]: value },
    keepSelectedDriveWhenRecalculating,
  );
}

export function changeCalculationParameterBulk(
  parameters: Partial<Record<ValueKey, LiteralParameterValue>>,
  keepSelectedDriveWhenRecalculating?: boolean,
): ChangeCalculationAction {
  function shouldRecalculate(
    selectedWindow: Window | undefined,
    getState: () => IState,
    filteredParameters: Partial<Record<ValueKey, LiteralParameterValue>>,
  ): boolean {
    return (
      selectedWindow?.nrwg ||
      _.intersection(
        getDriveCalculationParameters(getState, selectedWindow),
        Object.keys(filteredParameters),
      ).length > 0
    );
  }

  function willNotSelectDriveAfterRecalculation(
    selectedWindow: Window | undefined,
    getState: () => IState,
    filteredParameters: Partial<Record<string, LiteralParameterValue>>,
  ): boolean {
    return !!(
      !shouldRecalculate(selectedWindow, getState, filteredParameters) ||
      keepSelectedDriveWhenRecalculating
    );
  }

  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    dispatch(getSystemSeriesResult());
    const selectedWindow = getSelectedWindow(getState());
    const filteredParameters = pickBy(
      parameters,
      (value: LiteralParameterValue, key: string) =>
        value !== getState().parameters[key].value,
    );

    const openingOption = filteredParameters[ValueKey.OPENING_OPTION];
    if (openingOption) {
      if (openingOption === ValueKey.VALUEKEY_OPENING_STROKE) {
        dispatch(
          changeCalculationParameterBulk({
            [ValueKey.VALUEKEY_OPENING_ANGLE]: undefined,
            [ValueKey.VALUEKEY_OPENING_STROKE]: 347,
          }),
        );
      }
      if (openingOption === ValueKey.VALUEKEY_OPENING_ANGLE) {
        dispatch(
          changeCalculationParameterBulk({
            [ValueKey.VALUEKEY_OPENING_ANGLE]: 20,
            [ValueKey.VALUEKEY_OPENING_STROKE]: undefined,
          }),
        );
      }
    }

    let weightOption = filteredParameters[ValueKey.WEIGHT_OPTION];

    const sandwichElement =
      filteredParameters[ValueKey.VALUEKEY_SANDWICH_ELEMENT] ===
      FillType.SANDWICH_FILL;
    if (sandwichElement) {
      dispatch(
        changeCalculationParameterBulk({
          [ValueKey.WEIGHT_OPTION]: ValueKey.VALUEKEY_SASH_WEIGHT,
        }),
      );
      weightOption = ValueKey.VALUEKEY_SASH_WEIGHT;
    }

    if (weightOption) {
      if (weightOption === ValueKey.VALUEKEY_SASH_WEIGHT) {
        dispatch(
          changeCalculationParameterBulk({
            [ValueKey.VALUEKEY_GLAS_THICKNESS]: undefined,
          }),
        );
        dispatch(
          changeCalculationParameterBulk({
            [ValueKey.VALUEKEY_SASH_WEIGHT]: 30,
          }),
        );
      }
      if (weightOption === ValueKey.VALUEKEY_GLAS_THICKNESS) {
        dispatch(
          changeCalculationParameterBulk({
            [ValueKey.VALUEKEY_GLAS_THICKNESS]: 12,
          }),
        );
        dispatch(
          changeCalculationParameterBulk({
            [ValueKey.VALUEKEY_SASH_WEIGHT]: undefined,
          }),
        );
      }
    }

    const rangeOfApplication =
      filteredParameters[ValueKey.VALUEKEY_RANGE_OF_APPLICATION];
    if (!(rangeOfApplication && selectedWindow?.nrwg)) {
      Object.keys(filteredParameters).forEach(key =>
        dispatch(updateStore(key, filteredParameters[key as ValueKey])),
      );
    }

    if (rangeOfApplication) {
      if (selectedWindow?.nrwg) {
        dispatch(
          changeParametersState({
            ...selectedWindow.calculationParameters,
            ...EMPTY_PARAMETERS_STATES[
              rangeOfApplication as RangeOfApplication
            ],
          }),
        );
      }

      dispatch(resetProfileData());
    }

    if (
      changesValueKey(
        ValueKey.VALUEKEY_TYPE_OF_PROFILE_INPUT,
        filteredParameters,
      )
    ) {
      dispatch(storeSelectedConsoleSet(undefined));
    }

    if (
      changesValueKey(ValueKey.VALUEKEY_OPENING_DIRECTION, filteredParameters)
    ) {
      dispatch(updateSystemSeries(undefined));
      dispatch(resetProfileData());
      dispatch(getSystemSeriesResult());
    }

    if (!selectedWindow?.nrwg) {
      if (
        _.intersection(CONSOLE_PARAMETERS, Object.keys(filteredParameters))
          .length > 0 &&
        willNotSelectDriveAfterRecalculation(
          selectedWindow,
          getState,
          filteredParameters,
        )
      ) {
        dispatch(getSuitableConsoleSets());
      }
      if (
        _.intersection(DESMOKING_PARAMETERS, Object.keys(filteredParameters)) &&
        willNotSelectDriveAfterRecalculation(
          selectedWindow,
          getState,
          filteredParameters,
        )
      ) {
        dispatch(getGeomtricDesmoking());
      }
    }

    if (shouldRecalculate(selectedWindow, getState, filteredParameters)) {
      dispatch(
        recalculate({ keepSelectedDrive: keepSelectedDriveWhenRecalculating }),
      );
    }

    if (
      selectedWindow?.nrwg ||
      _.intersection(
        REQUIRED_CALCULATION_PARAMETERS_NRWG,
        Object.keys(filteredParameters),
      )
    ) {
      dispatch(getTestSearchOptions());
      dispatch(getRanges());
      dispatch(getValidation());
      dispatch(getPerformanceClasses());
    }

    if (
      !isEmpty(filteredParameters) &&
      getState().ui.selectedWindow !== undefined
    ) {
      dispatch(saveParameterStateInSelectedWindow());
    }

    if (notAllRequiredCalculationParametersDefined(getState())) {
      dispatch(storeWindDeflectors(undefined));
    }

    dispatch(getOrResetWindDeflectors());
    dispatch(getValidation());
  };
}

export function resetProfileData(): ThunkAction<
  Promise<SetSelectedBaseProfileAction>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch): Promise<SetSelectedBaseProfileAction> => {
    dispatch(resetConsoleSets());
    dispatch(setSelectedSystemSeries(undefined));
    dispatch(setSelectedSeries(undefined));
    dispatch(setSelectedSashProfile(undefined));
    dispatch(setSelectedFrameProfile(undefined));
    dispatch(setSelectedExchangeProfile(undefined));
    return dispatch(setSelectedBaseProfile(undefined));
  };
}

function clearFetchDelayTimer(): void {
  if (fetchDelayTimerId) {
    clearTimeout(fetchDelayTimerId);
  }
}

function abortFetch(): void {
  if (abortController) {
    abortController.abort();
  }
}

export const abortCalculations = (): void => {
  clearFetchDelayTimer();
  abortFetch();
};

export function resetSuitableConsoleSets(): ThunkAction<
  Promise<void>,
  IState,
  void,
  AnyAction
> {
  return async (dispatch: Dispatch): Promise<void> => {
    await dispatch(calculating(RequestTypes.CONSOLE_SETS));
    await dispatch(setSuitableConsoleSets([]));
    await dispatch(setHighlightedConsoleSet(undefined, 0));
    await dispatch(setHighlightedConsoleSet(undefined, 0));
  };
}

export function recalculate(params?: {
  includeInactive?: boolean;
  keepSelectedDrive?: boolean;
}): ThunkAction<Promise<void>, IState, void, AnyAction> {
  function getSelectedDrive(getState: () => IState): Drive | undefined {
    const selectedDriveFromWindow = getSelectedWindow(
      getState(),
    )?.selectedDrive;

    if (selectedDriveFromWindow) {
      if (
        [
          ...getState().calculationResult.suitableDrives,
          ...getState().calculationResult.partlyQualifiedDrives,
        ].find(d => d.name === selectedDriveFromWindow.name)
      ) {
        return selectedDriveFromWindow;
      }
    }

    if (params?.keepSelectedDrive) {
      return getMarkedDrive(getState());
    }
  }

  return async (dispatch: Dispatch, getState: () => IState): Promise<void> => {
    if (notAllRequiredCalculationParametersDefined(getState())) {
      dispatch(resetCalculationResults());
      return;
    }

    dispatch(calculating(RequestTypes.CALCULATION));
    if (!params?.keepSelectedDrive) {
      dispatch(resetSuitableConsoleSets());
    }

    fetchAndStore(
      RequestTypes.CALCULATION,
      async (calculationResult: CalculationResult) => {
        dispatch(finishedCalculation(RequestTypes.CALCULATION));
        dispatch(updateCalculationResult(calculationResult));
        const selectedDrive = getSelectedDrive(getState);

        if (!params?.keepSelectedDrive) {
          dispatch(markDrive(selectedDrive, calculationResult));
          dispatch(getSuitableConsoleSets());
        }

        if (params?.keepSelectedDrive && !selectedDrive) {
          //with the updated data the selected drive is not returned in the calculation results any more
          //so we need to move back to page CALCULATION to select a new drive
          dispatch(markDrive(undefined, calculationResult));
          dispatch(deselectDriveAndSaveAction());
          dispatch(switchPage(Pages.CALCULATION));
          dispatch(
            showSnackBar(
              'ERROR_MESSAGE_DRIVE_NOT_SUITABLE_ANY_MORE',
              undefined,
              false,
            ),
          );
        }
      },
      getState,
      { args: { includeInactive: params?.includeInactive } },
    );
  };
}

export function updateSystemSeries(
  series: SystemSeries | undefined,
): ThunkAction<void, IState, void, AnyAction> {
  return (dispatch: Dispatch): void => {
    dispatch(setSelectedSeries(Parameters.NO_SELECTION_SYSTEM_SERIES));
    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_SERIES,
        series ? series.series : Parameters.NO_SELECTION_SYSTEM_SERIES,
      ),
    );
    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_SYSTEM,
        series ? series.system : Parameters.NO_SELECTION_SYSTEM_SERIES,
      ),
    );
    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_SERIES_ID,
        series ? series.id : Parameters.NO_SELECTION,
      ),
    );

    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_SASH,
        Parameters.NO_SELECTION_SYSTEM_SERIES,
      ),
    );
    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_SASH_ID,
        Parameters.NO_SELECTION,
      ),
    );
    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_FRAME,
        Parameters.NO_SELECTION_SYSTEM_SERIES,
      ),
    );
    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_FRAME_ID,
        Parameters.NO_SELECTION,
      ),
    );
    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_EXCHANGE,
        Parameters.NO_SELECTION_SYSTEM_SERIES,
      ),
    );
    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_EXCHANGE_ID,
        Parameters.NO_SELECTION,
      ),
    );
    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_BASE,
        Parameters.NO_SELECTION_SYSTEM_SERIES,
      ),
    );
    dispatch(
      changeCalculationParameter(
        ValueKey.PROFILE_BASE_ID,
        Parameters.NO_SELECTION,
      ),
    );
  };
}

export function updateProfileData(
  systemSeries: SystemSeries,
  sashProfile: SashProfile,
  frameProfile: FrameProfile,
  exchangeProfile: ExchangeProfile | undefined,
  baseProfile: BaseProfile | undefined,
): ThunkAction<void, IState, void, AnyAction> {
  return (dispatch: Dispatch): void => {
    dispatch(calculating(RequestTypes.CONSOLE_SETS));
    dispatch(
      changeCalculationParameterBulk(
        {
          [ValueKey.PROFILE_SERIES]: systemSeries.series,
          [ValueKey.PROFILE_SERIES_ID]: systemSeries.id,
          [ValueKey.PROFILE_SYSTEM]: systemSeries.system,
          [ValueKey.PROFILE_SASH]: sashProfile?.itemNumber,
          [ValueKey.PROFILE_SASH_ID]: sashProfile?.id,
          [ValueKey.PROFILE_FRAME]: frameProfile?.itemNumber,
          [ValueKey.PROFILE_FRAME_ID]: frameProfile?.id,
          [ValueKey.PROFILE_EXCHANGE]:
            exchangeProfile?.itemNumber ||
            Parameters.NO_SELECTION_SYSTEM_SERIES,
          [ValueKey.PROFILE_EXCHANGE_ID]:
            exchangeProfile?.id || Parameters.NO_SELECTION,
          [ValueKey.PROFILE_BASE]:
            baseProfile?.itemNumber || Parameters.NO_SELECTION_SYSTEM_SERIES,
          [ValueKey.PROFILE_BASE_ID]:
            baseProfile?.id || Parameters.NO_SELECTION,
        },
        true,
      ),
    );
  };
}

export function markDrive(
  selectDrive: Drive | undefined,
  calculationResult: CalculationResult,
): ThunkAction<void, IState, void, AnyAction> {
  return (dispatch: Dispatch): void => {
    if (!calculationResult || !calculationResult.suitableDrives) {
      return;
    }

    const selectedDriveFromResult =
      selectDrive &&
      [
        ...calculationResult.suitableDrives,
        ...calculationResult.partlyQualifiedDrives,
      ].filter(d => selectDrive.name === d.name)[0];

    const topMostDrive =
      calculationResult.suitableDrives[0] ||
      sortByErrorCount(calculationResult.partlyQualifiedDrives)[0];

    dispatch(markDriveAction(selectedDriveFromResult || topMostDrive));
  };
}

export const updateAngleAndRecalculate: ActionCreator<
  ChangeCalculationAction
> = (value: number) => {
  return async (dispatch: Dispatch): Promise<void> => {
    return dispatch(
      changeCalculationParameter(ValueKey.VALUEKEY_OPENING_ANGLE, value),
    );
  };
};

export function changeParametersState(
  calculationParameters: ParametersState,
): ChangeParametersState {
  return {
    type: 'CHANGE_PARAMETERS_STATE',
    calculationParameters: calculationParameters,
  };
}

export function updateStrokeAndRecalculate(
  value: number | undefined,
): ThunkAction<Promise<void>, IState, void, UpdateCalculationResult> {
  return async (dispatch: Dispatch): Promise<void> => {
    // dispatch(updateStroke(value));
    return dispatch(
      changeCalculationParameter(ValueKey.VALUEKEY_OPENING_STROKE, value),
    );
  };
}

// export const updateStroke = (value: number | undefined): UpdateStroke => {
//   return {
//     type: 'UPDATE_STROKE',
//     value,
//   };
// };

function translateLogikalValue(key: ValueKey, value: string): string {
  if (key === ValueKey.VALUEKEY_OPENING_DIRECTION) {
    if (value === 'INWARD') {
      return OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS;
    } else if (value === 'OUTWARD') {
      return OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS;
    }
  }

  if (key === ValueKey.VALUEKEY_ASSEMBLY_TYPE) {
    if (value === 'FRAME') {
      return AssemblyType.ASSEMBLY_TYPE_FRAME;
    } else if (value === 'SASH') {
      return AssemblyType.ASSEMBLY_TYPE_SASH;
    }
  }

  if (key === ValueKey.VALUEKEY_DRIVE_POSITION) {
    if (value === 'OPPOSITE_THE_HINGE') {
      return DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE;
    } else if (value === 'SIDE') {
      return DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH;
    } else if (value === 'TRAVERSE') {
      return DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_TRAVERSE;
    }
  }

  if (key === ValueKey.VALUEKEY_OPENING_TYPE) {
    if (value === 'SIDE_HUNG') {
      return OpeningType.FENSTER_OEFFNUNGSART_DREH;
    } else if (value === 'BOTTOM_HUNG') {
      return OpeningType.FENSTER_OEFFNUNGSART_KIPP;
    } else if (value === 'TOP_HUNG') {
      return OpeningType.FENSTER_OEFFNUNGSART_KLAPP;
    }
  }

  return value;
}

function translateValue(key: ValueKey, value: string): string {
  if (key === ValueKey.VALUEKEY_RANGE_OF_APPLICATION) {
    if (value === 'facade') {
      return RangeOfApplication.FACADE;
    } else {
      return RangeOfApplication.ROOF;
    }
  }

  if (key === ValueKey.VALUEKEY_OPENING_TYPE) {
    if (value === 'bottomHung') {
      return OpeningType.FENSTER_OEFFNUNGSART_KIPP;
    } else if (value === 'topHung') {
      return OpeningType.FENSTER_OEFFNUNGSART_KLAPP;
    } else {
      return OpeningType.FENSTER_OEFFNUNGSART_DREH;
    }
  }

  if (key === ValueKey.VALUEKEY_OPENING_DIRECTION) {
    if (value === 'outward') {
      return OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_AUSWAERTS;
    } else {
      return OpeningDirection.FENSTER_OEFFNUNGSRICHTUNG_EINWAERTS;
    }
  }

  if (key === ValueKey.VALUEKEY_DRIVE_POSITION) {
    if (value === 'opposite') {
      return DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_BANDGEGENSEITE;
    } else if (value === 'side') {
      return DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_SEITLICH;
    } else {
      return DrivePosition.ANTRIEB_MONTAGEPOSITION_POSITION_TRAVERSE;
    }
  }

  if (key === ValueKey.LOCKING_DRIVE) {
    if (value === 'without') {
      return LockingDrive.WITHOUT_LOCKING_DRIVE;
    } else if (value === 'fra11') {
      return LockingDrive.FRA_11;
    } else {
      return LockingDrive.VLD;
    }
  }

  if (key === ValueKey.VALUEKEY_VOLTAGE) {
    if (value === '230V') {
      return Voltage.ANTRIEB_SPANNUNG_230V;
    } else if (value === '24V') {
      return Voltage.ANTRIEB_SPANNUNG_24V;
    } else {
      return Parameters.NO_SELECTION;
    }
  }

  if (key === ValueKey.VALUEKEY_DRIVE_TYPE) {
    if (value === 'chain') {
      return DriveType.ANTRIEB_TYP_KETTE;
    } else if (value === 'rackAndPinion') {
      return DriveType.ANTRIEB_TYP_ZAHNSTANGE;
    } else {
      return Parameters.NO_SELECTION;
    }
  }

  if (key === ValueKey.VALUEKEY_DRIVE_SERIES) {
    if (value === 'noSelection') {
      return Parameters.NO_SELECTION;
    } else {
      return value.toUpperCase();
    }
  }

  if (
    key === ValueKey.VALUEKEY_GEOMETRIC_AREA ||
    key === ValueKey.REVEAL ||
    key === ValueKey.REVEAL_STRUCTURE
  ) {
    return value.toUpperCase();
  }

  return value;
}

export function initializeCalculationParametersByURLParameters(): ThunkAction<
  Promise<void>,
  IState,
  void,
  UpdateCalculationResult | UpdateInputFromLogikal
> {
  return async dispatch => {
    const urlParameters = new URLSearchParams(window.location.search);

    function replaceValuesWithLanguageKeys(
      values: Record<string, string> | Array<string>,
      languageKeys: [string, number][],
    ) {
      for (const [key, value] of Object.entries(values)) {
        const entry = Array.isArray(values) ? value : key;
        if (entry === 'rangeOfApplication') {
          languageKeys.push(['fenster_edit_winginstallationtype', 1]);
        }
        if (entry === 'angleOfInstallation') {
          languageKeys.push(['tree_staticChild_fenster_einbauwinkel', 2]);
        }
        if (entry === 'openingType') {
          languageKeys.push(['fenster_edit_wingtype', 3]);
        }
        if (entry === 'openingDirection') {
          languageKeys.push(['fenster_edit_openingdirection', 4]);
        }
        if (entry === 'sashWidth') {
          languageKeys.push(['WINDOW_AREA_HELP_LEGEND_SASH_WIDTH', 5]);
        }
        if (entry === 'sashHeight') {
          languageKeys.push(['WINDOW_AREA_HELP_LEGEND_SASH_HEIGHT', 6]);
        }
        if (entry === 'sandWichElement') {
          languageKeys.push(['SANDWICH_ELEMENT', 7]);
        }
        if (entry === 'sashWeight') {
          languageKeys.push(['SASHWEIGHT', 8]);
        }
        /*
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  if (entry === 'glassThickness') {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    languageKeys.push('XXX');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  if (entry === 'openingAngle') {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    languageKeys.push('XXX');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  if (entry === 'openingStroke') {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    languageKeys.push('XXX');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  if (entry === 'windLoad') {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    languageKeys.push('XXX');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  if (entry === 'snowLoad') {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    languageKeys.push('XXX');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  if (entry === 'assemblyType') {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    languageKeys.push('XXX');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  if (entry === 'drivePosition') {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    languageKeys.push('XXX');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  if (entry === 'distanceToHinge') {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    languageKeys.push('XXX');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  if (entry === 'percentOfDistanceToHinge') {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    languageKeys.push('XXX');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  if (entry === 'numberOfDrives') {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    languageKeys.push('XXX');
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  */
        if (entry === 'assemblySpaceFrame') {
          languageKeys.push(['ASSEMBLY_SPACE_FRAME', 9]);
        }
        if (entry === 'assemblySpaceSashProfile') {
          languageKeys.push(['ASSEMBLY_SPACE_SASH_PROFILE', 10]);
        }
      }
    }

    // Logikal
    if (urlParameters.get('newCalculation')) {
      dispatch(updateInputFromLogikal(true));
      const values: Record<string, string> = JSON.parse(
        urlParameters.get('newCalculation')!,
      );

      // Validate Inputs
      const mandatoryFields: Array<string> = [
        ValueKey.VALUEKEY_OPENING_TYPE,
        ValueKey.VALUEKEY_OPENING_DIRECTION,
        ValueKey.VALUEKEY_SASH_WIDTH,
        ValueKey.VALUEKEY_SASH_HEIGHT,
        ValueKey.VALUEKEY_ASSEMBLY_SPACE_FRAME,
        ValueKey.VALUEKEY_ASSEMBLY_SPACE_SASH_PROFILE,
      ];

      let snackBarMessage = '';

      const missingFields: Array<string> = _.difference(
        mandatoryFields,
        Object.keys(values),
      );

      const sortByIndex = (
        a: [string, number],
        b: [string, number],
      ): 1 | -1 => {
        return a[1] < b[1] ? -1 : 1;
      };

      const missingKeys: [string, number][] = [];
      // Assign missing key to value
      const i = 0;
      if (missingFields.length > 0) {
        snackBarMessage +=
          missingKeys.length === 1
            ? missingKeys.push(['LOGIKAL_MISSING_FIELD', 0])
            : missingKeys.push(['LOGIKAL_MISSING_FIELDS', 0]);
      }

      replaceValuesWithLanguageKeys(missingFields, missingKeys);

      missingKeys.sort(sortByIndex);

      // if (
      //   values[ValueKey.VALUEKEY_SANDWICH_ELEMENT] === 'GLASS_FILL' &&
      //   !(
      //     Object.keys(values).includes(ValueKey.VALUEKEY_SASH_WEIGHT) ||
      //     Object.keys(values).includes(ValueKey.VALUEKEY_GLAS_THICKNESS)
      //   )
      // ) {
      //   snackBarMessage +=
      //     'Either sashWeight or glassThickness has to be defined!\n';
      // }

      // if (
      //   !(
      //     Object.keys(values).includes(ValueKey.VALUEKEY_OPENING_ANGLE) ||
      //     Object.keys(values).includes(ValueKey.VALUEKEY_OPENING_STROKE)
      //   )
      // ) {
      //   snackBarMessage +=
      //     'Either openingAngle or openingStroke has to be defined!\n';
      // }

      // if (
      //   values[ValueKey.VALUEKEY_OPENING_DIRECTION] === 'OUTWARD' &&
      //   !Object.keys(values).includes(ValueKey.VALUEKEY_ASSEMBLY_TYPE)
      // ) {
      //   snackBarMessage +=
      //     'When openingDirection OUTWARD is defined, assemblyType has to be defined as well!\n';
      // }

      // if (
      //   !(
      //     Object.keys(values).includes(ValueKey.VALUEKEY_DISTANCE_TO_HINGE) ||
      //     Object.keys(values).includes(
      //       ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
      //     )
      //   )
      // ) {
      //   snackBarMessage +=
      //     'Either distanceToHinge or percentOfDistanceToHinge has to be defined!\n';
      // }

      let additionalText = '';

      if (
        values[ValueKey.VALUEKEY_RANGE_OF_APPLICATION] === 'FACADE' &&
        (Number(values[ValueKey.VALUEKEY_ANGLE_OF_INSTALLATION]) < 61 ||
          Number(values[ValueKey.VALUEKEY_ANGLE_OF_INSTALLATION]) > 120)
      ) {
        additionalText +=
          'Einbauwinkel für Fassade muss zwischen 61 und 120 Grad haben!\n';
      }

      if (
        values[ValueKey.VALUEKEY_RANGE_OF_APPLICATION] === 'ROOF' &&
        (Number(values[ValueKey.VALUEKEY_ANGLE_OF_INSTALLATION]) < 0 ||
          Number(values[ValueKey.VALUEKEY_ANGLE_OF_INSTALLATION]) > 60)
      ) {
        additionalText +=
          'Einbauwinkel für Dach muss zwischen 0 und 60 Grad haben!\n';
      }

      if (
        Number(values[ValueKey.VALUEKEY_SASH_WIDTH]) < 1 ||
        Number(values[ValueKey.VALUEKEY_SASH_WIDTH]) > 20000
      ) {
        additionalText +=
          'Flügelbreite muss zwischen 1 und 20000 angegeben werden!\n';
      }

      if (
        Number(values[ValueKey.VALUEKEY_SASH_HEIGHT]) < 1 ||
        Number(values[ValueKey.VALUEKEY_SASH_HEIGHT]) > 20000
      ) {
        additionalText +=
          'Flügelhöhe muss zwischen 1 and 20000 angegeben werden!\n';
      }

      if (
        values[ValueKey.VALUEKEY_SANDWICH_ELEMENT] === 'SANDWICH_FILL' &&
        values[ValueKey.VALUEKEY_SASH_WEIGHT] === undefined
      ) {
        additionalText +=
          'Wenn Sandwich-Element vorhanden ist, muss das Flügelgewicht auch angegeben werden!\n';
      }

      if (
        values[ValueKey.VALUEKEY_SASH_WEIGHT] !== undefined &&
        (Number(values[ValueKey.VALUEKEY_SASH_WEIGHT]) < 1 ||
          Number(values[ValueKey.VALUEKEY_SASH_WEIGHT]) > 3000)
      ) {
        additionalText +=
          'Flügelgewicht muss zwischen 1 and 3000 angegeben werden!\n';
      }

      if (
        values[ValueKey.VALUEKEY_GLAS_THICKNESS] !== undefined &&
        (Number(values[ValueKey.VALUEKEY_GLAS_THICKNESS]) < 1 ||
          Number(values[ValueKey.VALUEKEY_GLAS_THICKNESS]) > 1200)
      ) {
        additionalText +=
          'Glasstärke muss zwischen 1 and 1200 angegeben werden!\n';
      }

      if (
        values[ValueKey.VALUEKEY_OPENING_ANGLE] !== undefined &&
        (Number(values[ValueKey.VALUEKEY_OPENING_ANGLE]) < 1 ||
          Number(values[ValueKey.VALUEKEY_OPENING_ANGLE]) > 180)
      ) {
        additionalText +=
          'Öffnungswinkel muss zwischen 1 and 180 Grad haben!\n';
      }

      if (
        values[ValueKey.VALUEKEY_OPENING_STROKE] !== undefined &&
        (Number(values[ValueKey.VALUEKEY_OPENING_STROKE]) < 1 ||
          Number(values[ValueKey.VALUEKEY_OPENING_STROKE]) > 2000)
      ) {
        additionalText +=
          'Öffnungshub muss zwischen 1 and 2000 angegeben werden!\n';
      }

      if (
        values[ValueKey.VALUEKEY_WIND_LOAD] !== undefined &&
        (Number(values[ValueKey.VALUEKEY_WIND_LOAD]) < 1 ||
          Number(values[ValueKey.VALUEKEY_WIND_LOAD]) > 10000)
      ) {
        additionalText +=
          'Windlast muss zwischen 1 and 10000 angegeben werden!\n';
      }

      if (
        values[ValueKey.VALUEKEY_SNOW_LOAD] !== undefined &&
        values[ValueKey.VALUEKEY_RANGE_OF_APPLICATION] === 'FACADE' &&
        (Number(values[ValueKey.VALUEKEY_SNOW_LOAD]) < 1 ||
          Number(values[ValueKey.VALUEKEY_SNOW_LOAD]) > 10000)
      ) {
        additionalText +=
          'Schneelast muss zwischen 1 and 10000 angegeben werden!\n';
      }

      // if (
      //   values[ValueKey.VALUEKEY_RANGE_OF_APPLICATION] === 'FACADE' &&
      //   values[ValueKey.VALUEKEY_OPENING_DIRECTION] === 'INWARD' &&
      //   values[ValueKey.VALUEKEY_ASSEMBLY_TYPE] === undefined
      // ) {
      //   snackBarMessage +=
      //     'assemblyType is mandatory when rangeOfApplication is FACADE and openingDirection is INWARD!\n';
      // }

      if (
        (values[ValueKey.VALUEKEY_DISTANCE_TO_HINGE] !== undefined &&
          (values[ValueKey.VALUEKEY_OPENING_TYPE] === 'BOTTOM_HUNG' ||
            values[ValueKey.VALUEKEY_OPENING_TYPE] === 'TOP_HUNG') &&
          (Number(values[ValueKey.VALUEKEY_DISTANCE_TO_HINGE]) < 1 ||
            Number(values[ValueKey.VALUEKEY_DISTANCE_TO_HINGE]) >
              Number(values[ValueKey.VALUEKEY_SASH_HEIGHT]))) ||
        (values[ValueKey.VALUEKEY_OPENING_TYPE] === 'SIDE_HUNG' &&
          (Number(values[ValueKey.VALUEKEY_DISTANCE_TO_HINGE]) < 1 ||
            Number(values[ValueKey.VALUEKEY_DISTANCE_TO_HINGE]) >
              Number(values[ValueKey.VALUEKEY_SASH_WIDTH])))
      ) {
        additionalText +=
          values[ValueKey.VALUEKEY_OPENING_TYPE] === 'SIDE_HUNG'
            ? 'Der Abstand zum Scharnier muss zwischen 1 and Flügelbreite angegeben werden!\n'
            : 'Der Abstand zum Scharnier muss zwischen 1 and Flügelhöhe angegeben werden!\n';
      }

      if (
        values[ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE] !== undefined &&
        (Number(values[ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE]) < 1 ||
          Number(values[ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE]) > 100)
      ) {
        additionalText +=
          'Der prozentuale Abstand zum Scharnier muss zwischen 1 and 100 angegeben werden!\n';
      }

      if (
        values[ValueKey.VALUEKEY_DRIVE_POSITION] === 'SIDE' &&
        Number(values[ValueKey.VALUEKEY_NUMBER_OF_DRIVES]) < 1 &&
        Number(values[ValueKey.VALUEKEY_NUMBER_OF_DRIVES]) > 2
      ) {
        additionalText +=
          'Mit Antriebsposition seitliche Montage, muss die Anzahl der Antriebe zwischen 1 und 2 liegen!\n';
      }

      if (
        (values[ValueKey.VALUEKEY_DRIVE_POSITION] === 'OPPOSITE_THE_HINGE' ||
          values[ValueKey.VALUEKEY_DRIVE_POSITION] === 'TRAVERSE') &&
        Number(values[ValueKey.VALUEKEY_NUMBER_OF_DRIVES]) < 1 &&
        Number(values[ValueKey.VALUEKEY_NUMBER_OF_DRIVES]) > 8
      ) {
        additionalText +=
          'Mit Antriebsposition seitliche Montage, muss die Anzahl der Antriebe zwischen 1 und 8 liegen!\n';
      }

      if (
        values[ValueKey.VALUEKEY_ASSEMBLY_SPACE_FRAME] !== undefined &&
        (Number(values[ValueKey.VALUEKEY_ASSEMBLY_SPACE_FRAME]) < 1 ||
          Number(values[ValueKey.VALUEKEY_ASSEMBLY_SPACE_FRAME]) > 500)
      ) {
        additionalText +=
          'Montageplatz Blendrahmen muss zwischen 1 and 500 angegeben werden!\n';
      }

      if (
        values[ValueKey.VALUEKEY_ASSEMBLY_SPACE_SASH_PROFILE] !== undefined &&
        (Number(values[ValueKey.VALUEKEY_ASSEMBLY_SPACE_SASH_PROFILE]) < 1 ||
          Number(values[ValueKey.VALUEKEY_ASSEMBLY_SPACE_SASH_PROFILE]) > 500)
      ) {
        additionalText +=
          'Montageplatz FlügelprofilSchließen muss zwischen 1 and 500 angegeben werden!\n';
      }

      const translateValues =
        (languageKeys: [string, number][], additionalText: string) =>
        (formatMessage: (k: MessageDescriptor) => string) => {
          let i = 0;
          for (const languageKey in languageKeys) {
            if (languageKey !== 'client') {
              if (i === 0) {
                snackBarMessage = '';
                snackBarMessage += formatMessage({ id: languageKeys[i][0] });
              } else if (i === 1) {
                snackBarMessage +=
                  '\n ' + formatMessage({ id: languageKeys[i][0] });
              } else {
                snackBarMessage +=
                  ',\n ' + formatMessage({ id: languageKeys[i][0] });
              }
              i++;
            }
          }
          if (additionalText) {
            snackBarMessage += '\n' + additionalText;
          }
          return snackBarMessage;
        };

      if (additionalText === '') {
        // Assign language key to value
        const languageKeys: [string, number][] = [];
        languageKeys.push(['LOGIKAL_SUCCESS', 0]);
        replaceValuesWithLanguageKeys(values, languageKeys);

        languageKeys.sort(sortByIndex);

        dispatch(
          showSnackBar(undefined, translateValues(languageKeys, ''), true),
        );
      } else {
        dispatch(
          showSnackBar(
            undefined,
            translateValues(missingKeys, additionalText),
            false,
          ),
        );
      }

      // Fill in Logikal values
      Object.entries(values).forEach(([key, value]) => {
        if (
          [
            ValueKey.VALUEKEY_RANGE_OF_APPLICATION,
            ValueKey.VALUEKEY_ANGLE_OF_INSTALLATION,
            ValueKey.VALUEKEY_OPENING_TYPE,
            ValueKey.VALUEKEY_OPENING_DIRECTION,
            ValueKey.VALUEKEY_SASH_WIDTH,
            ValueKey.VALUEKEY_SASH_HEIGHT,
            ValueKey.VALUEKEY_SANDWICH_ELEMENT,
            ValueKey.VALUEKEY_SASH_WEIGHT,
            ValueKey.VALUEKEY_GLAS_THICKNESS,
            ValueKey.VALUEKEY_OPENING_ANGLE,
            ValueKey.VALUEKEY_OPENING_STROKE,
            ValueKey.VALUEKEY_WIND_LOAD,
            ValueKey.VALUEKEY_SNOW_LOAD,
            ValueKey.VALUEKEY_ASSEMBLY_TYPE,
            ValueKey.VALUEKEY_DRIVE_POSITION,
            ValueKey.VALUEKEY_DISTANCE_TO_HINGE,
            ValueKey.VALUEKEY_PERCENT_OF_DISTANCE_TO_HINGE,
            ValueKey.VALUEKEY_NUMBER_OF_DRIVES,
            ValueKey.VALUEKEY_ASSEMBLY_SPACE_FRAME,
            ValueKey.VALUEKEY_ASSEMBLY_SPACE_SASH_PROFILE,
          ].includes(key as ValueKey)
        ) {
          dispatch(
            changeCalculationParameter(
              key as ValueKey,
              translateLogikalValue(key as ValueKey, value),
            ),
          );
        }
      });
    }

    // Fill in other values
    if (urlParameters.get(ValueKey.VALUEKEY_RANGE_OF_APPLICATION)) {
      dispatch(
        changeCalculationParameter(
          ValueKey.VALUEKEY_RANGE_OF_APPLICATION,
          translateValue(
            ValueKey.VALUEKEY_RANGE_OF_APPLICATION,
            urlParameters.get(ValueKey.VALUEKEY_RANGE_OF_APPLICATION)!,
          ),
        ),
      );
    }

    Array.from(urlParameters.entries()).forEach(([key, value]) => {
      if (
        [
          ValueKey.VALUEKEY_OPENING_TYPE,
          ValueKey.VALUEKEY_OPENING_DIRECTION,
          ValueKey.VALUEKEY_DRIVE_POSITION,
          ValueKey.LOCKING_DRIVE,
          ValueKey.VALUEKEY_VOLTAGE,
          ValueKey.VALUEKEY_DRIVE_TYPE,
          ValueKey.VALUEKEY_DRIVE_SERIES,
          ValueKey.VALUEKEY_GEOMETRIC_AREA,
          ValueKey.REVEAL,
          ValueKey.REVEAL_STRUCTURE,
        ].includes(key as ValueKey)
      ) {
        dispatch(
          changeCalculationParameter(
            key as ValueKey,
            translateValue(key as ValueKey, value),
          ),
        );
      }
    });
  };
}
